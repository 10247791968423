@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Estilos base */

.allContent {
  width: 100%;
  margin: 20px 0;
  background-color: #000d20;
}

.allContent h2 {
  text-align: center;
  padding: 20px 0;
  color: white;
  font-weight: bold;
  font-size: 2em !important;
}
.content {
  max-width: 1380px;
  padding: 40px 70px;
  margin: 10px auto;
  color: white;
  display: flex;
  gap: 100px;
  flex-direction: column;
}

.content p,
.content h2,
.content a,
.content h3,
.content h4 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.benefit {
  display: flex;
  gap: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.benefit .texts {
  max-width: 500px;
}
.advantageImg {
  position: relative;
}

.advantageImg img {
  z-index: 2;
}

.advantageImg span {
  width: 100%;
  max-width: 400px;
  height: 200px;
  background: linear-gradient(to right, #363636 30%, #114796 100%);
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 60%;
  top: 65%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.benefit img {
  max-width: 400px;
  border-radius: 5px;
  z-index: 2;
  position: relative;
}

.benefitOdd span {
  left: 40%;
  top: 65%;
  transform: translate(-50%, -50%);
}
.benefitOdd .texts {
  order: 1;
}

.benefit div h3 {
  font-size: 1.2em;
  font-weight: 500 !important;
}

.benefit div p {
  font-weight: 400;
  margin: 0;
}

@media screen and (max-width: 1000px) {
  .content {
    padding: 20px;
  }
  .allContent h2 {
    font-size: 1.5em !important;
  }
  .advantageImg span {
    display: none;
  }
  .benefit {
    flex-direction: column;
    gap: 40px;
    max-width: 500px;
    margin: 0 auto;
  }
  .benefit img {
    width: 100%;
    max-width: 500px;
    border: 3px solid #114796;
  }
  .benefit div {
    order: 1;
    width: 100%;
    max-width: 500px;
  }

  .benefit .texts {
    order: 2;
  }
}
