.master-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all linear 0.3s;
  z-index: 100;
}
.float:hover {
  color: white;
  background-color: #2af173;
  font-weight: bold;
}

.my-float {
  font-size: 30px;
  width: 30px;
}
