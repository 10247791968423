.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy h1 {
  font-size: 24px;
  margin-bottom: 40px;
  margin-top: 20px;
  text-align: center;
  font-weight: 900;
}

.policy-section {
  margin-bottom: 30px;
}

.policy-section h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.policy-section p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}
