@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: 'Encode Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.master-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all linear 0.3s;
  z-index: 100;
}
.float:hover {
  color: white;
  background-color: #2af173;
  font-weight: bold;
}

.my-float {
  font-size: 30px;
  width: 30px;
}

html {
  scroll-behavior: smooth;
}

.totalDiv {
  font-family: "Poppins", sans-serif;
  color: white;
  width: 100%;
  padding: 10px;
  background-color: none;
}

ul {
  list-style: none;
  display: flex;
}
.totalDiv p {
  font-family: "Inter", sans-serif;
}

.totalDiv nav {
  max-width: 1380px;
  margin: 0px auto;
  grid-gap: 20px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalDiv nav .logo {
  width: 100%;
  max-width: 150px;
}
.totalDiv nav .logo img {
  width: 100%;
}

.totalNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.talkAndLogin {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.talkWithUs {
  border: 1px solid #fff;
  padding: 10px !important;
  border-radius: 5px;
}

.talkWithUs:hover {
  background-color: rgb(255, 255, 255, 0.1);
}

.loginButton {
  background-color: #3271cf;
  padding: 10px 30px !important;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 0;
}

.loginButton:hover {
  background-color: #2a5cbf;
}

.navItens {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  margin: 0;
  margin-top: 5px;
  width: 100%;
  padding: 0;
}

.navItens li {
  position: relative;
}
.navItens li a {
  text-decoration: none;
}

.navItens li p,
.navItens li a {
  color: white;
  font-weight: 500;
  padding: 5px;
  margin-bottom: 0;
}

.navItens li:hover {
  color: white;
  text-decoration: none;
}

.middleItens {
  grid-gap: 24px;
  gap: 24px;
}

.prime {
  color: #37cf32 !important;
}
.middleItens li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: width 0.4s ease;
}

.navItens li p {
  padding: 0px 5px;
}

.middleItens li:hover::after {
  width: 100%;
}
.lineNavbar {
  width: 100%;
  max-width: 100%;
  padding: 0px 10px;
  margin: 5px;
}

.responsiveMenu,
.closeMenuIcon {
  display: none;
}

li .logout {
  padding: 5px;
}

.arrowMenu {
  display: none;
}

.clientArea::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: width 0.4s ease;
}
.clientArea:hover::after {
  width: 100%;
}

.clientArea {
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .navItens {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 930px) {
  .totalDiv nav {
    justify-content: space-between;
    align-items: center;
  }
  .clientArea {
    background-color: #37cf32;
    border-radius: 5px;
    padding: 10px 20px !important;
  }
  .logoAndClose {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
  }
  .logoAndClose {
    width: 100%;
  }
  .arrowMenu {
    display: block;
  }
  .totalDiv nav .logo img {
    width: 100%;
    max-width: 150px;
  }
  .totalNavbar {
    flex-direction: row;
  }

  .navItens li:hover:after {
    width: 0;
  }

  .navItens {
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: none;
    margin: 0;
    padding: 10px;
    align-items: flex-start;
    background: #000d20;
    grid-gap: 20px;
    gap: 20px;
  }

  .navItens li {
    width: 100%;
    text-align: left;
    padding: 10px 5px;
    border-radius: 5px;
    margin: 0 auto;
  }

  .navItens a::after {
    display: none;
  }

  .totalDiv .menuOpentrue {
    display: flex;
    z-index: 100;
  }
  .lineNavbar {
    display: none;
  }
  .closeMenuIcon {
    padding: 5px;
    display: block;
    width: 20px;
  }
  .responsiveMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .responsiveMenu img:nth-child(2) {
    width: 30px;
    height: auto;
  }
  .middleItens {
    flex-direction: column;
    padding: 0;
    font-size: 1em;
    margin-top: 20px;
    font-weight: 500;
    width: 100%;
  }
  .navItens li p,
  .navItens li a {
    font-size: 1.7em;
    width: 100%;
    font-weight: 400;
  }
  .talkAndLogin {
    flex-direction: column;
    padding: 0;
    width: 100%;
    grid-gap: 0;
    gap: 0;
  }
  .talkAndLogin li a {
    width: 100%;
    display: block;
    text-align: center;
    margin: 0 auto;
  }
  .middleItens li {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.style_banner__3P530 {
  background-image: url(/static/media/backgroundHeader.cfa99b88.png);
  width: 100%;
  min-height: 450px;
  background-size: cover;
  position: relative;
  padding: 10px;
}
.style_banner__3P530 h2,
.style_banner__3P530 a {
  font-family: "Poppins", sans-serif;
}

.style_banner__3P530 a:visited,
.style_banner__3P530 a:hover {
  color: white;
  text-decoration: none;
}

.style_bannerText__3WpIc {
  max-width: 1380px;
  margin: auto;
}

.style_bannerTextInfos__1FDup {
  position: relative;
  top: 25%;
}

.style_textContainer__32PYb {
  margin-top: 40px;
  position: relative;
  min-height: 200px;
  overflow: hidden;
  width: 100%;
}

.style_textContainer__32PYb h2 {
  max-width: 600px;
  color: white;
  font-weight: bold;
  font-size: 2.4em;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.style_buttonKnow__HTI1u {
  background-color: #3271cf;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  font-size: 0.8em;
  font-weight: 400;
  margin-top: 20px;
}

.style_buttonKnow__HTI1u:hover {
  background-color: #2a5cbf;
}

.style_bulletsContainer__2rME7 {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 20px;
  align-items: center;
}

.style_bullet__6AsFE {
  cursor: pointer;
  width: 60px;
  height: 8px;
}

.style_bulletBar__cFvxK {
  background: #eee;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.style_bulletFill__1PINd {
  background: #3271cf;
  height: 100%;
  transition: width 0.1s linear;
}

.style_bulletBar__cFvxK:hover {
  background-color: #94aee2;
}

@media (max-width: 768px) {
  .style_banner__3P530 h2 {
    font-size: 2em;
    max-width: 500px;
    margin-top: 20px;
  }
  .style_banner__3P530 {
    min-height: 400px;
    padding: 10px;
  }
  .style_totalDiv__3G6s3 {
    padding: 0;
  }
  .style_textContainer__32PYb {
    margin-top: 30px;
    min-height: 190px;
  }
  .style_textContainer__32PYb h2 {
    max-width: 540px;
    font-size: 2em;
    max-width: 500px;
  }
}

/* Tela pequena (smartphones) */
@media (max-width: 520px) {
  .style_banner__3P530 h2 {
    font-size: 1.4em;
    max-width: 400px;
  }
  .style_banner__3P530 {
    min-height: 350px;
  }
  .style_textContainer__32PYb {
    margin-top: 20px;
    min-height: 150px;
  }
  .style_textContainer__32PYb h2 {
    max-width: 400px;
    font-size: 1.4em;
    max-width: 400px;
  }
}

@media (max-width: 380px) {
  .style_banner__3P530 h2 {
    font-size: 1.3em;
    max-width: 400px;
  }
  .style_banner__3P530 {
    min-height: 350px;
  }
  .style_textContainer__32PYb {
    margin-top: 10px;
    min-height: 140px;
  }
  .style_textContainer__32PYb h2 {
    max-width: 320px;
    font-size: 1.3em;
    max-width: 400px;
  }
}

@media (max-width: 340px) {
  .style_textContainer__32PYb h2 {
    max-width: 320px;
    font-size: 1.2em;
    max-width: 400px;
  }
}

.container-HeaderFooter {
  align-content: center;
}

.row-partner {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 10px auto;
  max-width: 800px;
  padding: 0 5px;
}

.column-partner-modifier {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  text-align: center;
}

.partner-size-modifier {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.partner-size-modifier-2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transform: translateX(40%);
  margin-top: 4.7px;
}

@media only screen and (max-width: 600px) {
  .container-HeaderFooter {
    display: inline-block;
    position: relative;
  }
  .partner-size,
  .partner-size-modifier {
    width: 70px;
  }
  .partner-size-modifier-2 {
    width: 70px;
    height: 35px;
    transform: translateX(0%);
  }

  .column-partner-modifier {
    margin-top: 7.5px;
  }

  .row-partner {
    width: 100%;
    display: flex;
  }

  .column-partner {
    padding: 5px;
  }

  .adjust {
    margin-top: 1.5px;
  }
}

.style_totalInfo__1bdrK {
  width: 100%;
  padding: 20px;
}

.style_sectionCarousel__2x3-a h2,
.style_sectionCarousel__2x3-a p,
.style_sectionCarousel__2x3-a button,
.style_sectionCarousel__2x3-a span,
.style_sectionCarousel__2x3-a h3 {
  font-family: "Inter", sans-serif;
}

.style_sectionCarousel__2x3-a {
  max-width: 1380px;
  height: 650px;
  margin: 0 auto;
  grid-gap: 10px;
  gap: 10px;
}
.style_sectionCarousel__2x3-a img {
  height: 600px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.style_img-carousel-1__3WFjl {
  object-fit: fill;
}

.style_img-carousel-2__1RF6- {
  object-fit: cover;
  object-position: 50% 100%;
}

.style_title__-uupA {
  margin: 0 auto 10px;
  font-weight: 500;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: clamp(2.3em, 2vw, 1.4em);
  font-weight: bold;
  font-size: 2em;
}

.style_titleBanner__2REja {
  background-color: rgba(77, 77, 77, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 50%;
  margin: 0 auto;
  padding: 5px 10px;
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  font-size: 1.3em !important;
  border-radius: 5px;
}

.style_bannerText__2oRqi {
  max-width: 1380px;
  margin: auto;
}

.style_bannerTextInfos__2-Dij {
  position: relative;
}

.style_captionCarousel__24ztR {
  z-index: 1;
  margin: 0 auto 10px;
  font-weight: 500;
  width: 60%;
  text-align: center;
  font-size: clamp(1.1em, 2vw, 1.8em);
  font-weight: bold;
  color: white;
  background-color: rgba(77, 77, 77, 0.5);
  border-radius: 8px;
}

.style_bulletsDiv__WdpKx {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  margin-top: 10px;
}

.style_bulletsContainer__39PPM {
  display: flex;
  flex-direction: column;
  height: 600px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: flex-end;
}

.style_bullet__1SKTG {
  cursor: pointer;
  width: 60px;
  height: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.style_bulletBar__1k2uK {
  background: #eee;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.style_bulletFill__3oOaS {
  background: #3271cf;
  height: 100%;
  transition: width 0.1s linear;
}

.style_bulletBar__1k2uK:hover {
  background-color: #94aee2;
}

@media (max-width: 768px) {
  .style_sectionCarousel__2x3-a {
    height: 550px;
  }
  .style_sectionCarousel__2x3-a img,
  .style_bulletsContainer__39PPM {
    max-height: 500px;
  }
  .style_captionCarousel__24ztR {
    width: 70%;
    margin: 0 auto 8px;
  }
  .style_bulletsDiv__WdpKx {
    margin-top: 8px;
    grid-gap: 6px;
    gap: 6px;
  }
  .style_bullet__1SKTG {
    width: 32px;
  }
}

/* Tela pequena (smartphones) */
@media (max-width: 520px) {
  .style_sectionCarousel__2x3-a {
    height: 450px;
  }
  .style_sectionCarousel__2x3-a img,
  .style_bulletsContainer__39PPM {
    max-height: 400px;
  }
  .style_captionCarousel__24ztR {
    width: 80%;
    margin: 0 auto 6px;
  }
  .style_bulletsDiv__WdpKx {
    margin-top: 6px;
    grid-gap: 4px;
    gap: 4px;
  }
  .style_bullet__1SKTG {
    width: 24px;
  }
}

@media (max-width: 380px) {
  .style_sectionCarousel__2x3-a {
    height: 350px;
  }
  .style_sectionCarousel__2x3-a img,
  .style_bulletsContainer__39PPM {
    max-height: 300px;
  }
  .style_captionCarousel__24ztR {
    width: 90%;
    margin: 0 auto 4px;
  }
  .style_bulletsDiv__WdpKx {
    margin-top: 4px;
  }
  .style_bullet__1SKTG {
    width: 20px;
  }
}

@media (max-width: 340px) {
  .style_sectionCarousel__2x3-a {
    height: 250px;
  }
  .style_sectionCarousel__2x3-a img,
  .style_bulletsContainer__39PPM {
    max-height: 200px;
  }
  .style_captionCarousel__24ztR {
    width: 95%;
    margin: 0 auto 2px;
  }
  .style_bullet__1SKTG {
    width: 16px;
  }
}

.style_section__LmBJy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000d20;
  padding: 20px;
}

.style_section__LmBJy .style_totalContent__1THtE {
  max-width: 1380px;
  width: 100%;
}

.style_totalContent__1THtE a,
.style_totalContent__1THtE a:visited,
.style_totalContent__1THtE a:hover {
  color: white;
  text-decoration: none;
}

.style_totalContent__1THtE p,
.style_totalContent__1THtE h2,
.style_totalContent__1THtE button,
.style_totalContent__1THtE h3 {
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
}

.style_totalContent__1THtE h3 {
  font-weight: 300;
  font-size: 1.4em;
}

.style_textOffer__3WV4t {
  max-width: 80%;
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 0.9em;
}

.style_totalContent__1THtE h2 {
  color: white;
  padding: 20px 0;
  font-weight: bold;
  font-size: 2em;
}
.style_titleAndLogo__LImhT {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.style_titleAndLogo__LImhT img {
  width: 100%;
  max-width: 30px;
}
.style_whatMerxOffer__31hKV {
  display: grid;
  grid-template-areas:
    "parcerias autoproducao projetos "
    "mercadoLivre autoproducao consultoria";
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: 10px;
  gap: 10px;
  color: white;
  margin: 10px auto 0;
  max-width: 1200px;
}

.style_parcerias__CqBTg {
  grid-area: parcerias;
  background-color: rgba(77, 77, 77, 0.1);
  /* Exemplo de cor de fundo */
  padding: 10px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px); /* Borrado */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  position: relative;
  display: grid;
}

.style_projetos__1dpBs h3 {
  margin: 0;
}

.style_autoproducao__1Sq__ {
  grid-area: autoproducao;
  background-color: rgba(77, 77, 77, 0.1);
  /* Exemplo de cor de fundo */
  padding: 10px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px); /* Borrado */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  position: relative;
  display: grid;
}

.style_projetos__1dpBs {
  grid-area: projetos;
  background-color: rgba(77, 77, 77, 0.1);
  /* Exemplo de cor de fundo */
  padding: 10px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px); /* Borrado */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.style_projetos__1dpBs,
.style_parcerias__CqBTg {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto) auto;
  grid-template-areas:
    "titulo titulo"
    "paragrafo paragrafo"
    "botao imagem";
  grid-gap: 0px 10px;
  gap: 0px 10px;
}

.style_autoproducao__1Sq__ {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto) auto;
  grid-template-areas:
    "titulo titulo"
    "paragrafo paragrafo"
    "botao botao "
    "imagem imagem";
  grid-gap: 5px 10px;
  gap: 5px 10px;
}

.style_autoproducao__1Sq__ img {
  width: 100%;
}
.style_mercadoLivre__3YqWW,
.style_consultoria__17j6Q {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(3, auto) 1fr;
  grid-template-areas:
    "titulo titulo"
    "paragrafo paragrafo"
    "botao botao"
    "imagem imagem";
  grid-gap: 5px 0;
  gap: 5px 0;
}

.style_mercadoLivre__3YqWW img,
.style_consultoria__17j6Q img {
  align-self: center;
}

.style_mercadoLivre__3YqWW img,
.style_consultoria__17j6Q img {
  margin: 10px auto 0;
}

.style_projetos__1dpBs button,
.style_parcerias__CqBTg button {
  max-height: 40px;
  align-self: flex-end;
}

.style_mercadoLivre__3YqWW {
  grid-area: mercadoLivre;
  background-color: rgba(77, 77, 77, 0.1);
  /* Exemplo de cor de fundo */
  padding: 10px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px); /* Borrado */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  position: relative;
}

.style_consultoria__17j6Q {
  grid-area: consultoria;
  background-color: rgba(77, 77, 77, 0.1);
  /* Exemplo de cor de fundo */
  padding: 10px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px); /* Borrado */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  height: 100%;
}

.style_parcerias__CqBTg img,
.style_projetos__1dpBs img {
  width: 60px;
  height: auto;
}

.style_exploreAndImage__3uMFV {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.style_projetos__1dpBs button,
.style_mercadoLivre__3YqWW button,
.style_autoproducao__1Sq__ button,
.style_consultoria__17j6Q button,
.style_parcerias__CqBTg button {
  width: 100%;
  color: white;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(77, 77, 77, 0.1);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  padding: 5px 0;
  border-radius: 20px;
  grid-gap: 10%;
  gap: 10%;
}

.style_projetos__1dpBs button:hover,
.style_mercadoLivre__3YqWW button:hover,
.style_autoproducao__1Sq__ button:hover,
.style_consultoria__17j6Q button:hover,
.style_parcerias__CqBTg button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.style_exploreAndImageCol__23bDC button {
  width: 100%;
}

.style_parcerias__CqBTg button,
.style_projetos__1dpBs button,
.style_mercadoLivre__3YqWW button,
.style_autoproducao__1Sq__ button,
.style_consultoria__17j6Q button {
  margin-top: 10px;
}

.style_parcerias__CqBTg img {
  -webkit-filter: drop-shadow(
    -7px -12px 9px rgba(63, 44, 92, 1)
  );
          filter: drop-shadow(
    -7px -12px 9px rgba(63, 44, 92, 1)
  ); /* deslocamento x, y, desfoque, cor */
}

.style_projetos__1dpBs img {
  -webkit-filter: drop-shadow(
    -7px -12px 9px rgba(62, 104, 254, 0.2)
  );
          filter: drop-shadow(
    -7px -12px 9px rgba(62, 104, 254, 0.2)
  ); /* deslocamento x, y, desfoque, cor */
}

.style_mercadoLivre__3YqWW img,
.style_consultoria__17j6Q img {
  -webkit-filter: drop-shadow(
    -7px -12px 9px rgba(63, 136, 215, 0.2)
  );
          filter: drop-shadow(
    -7px -12px 9px rgba(63, 136, 215, 0.2)
  ); /* deslocamento x, y, desfoque, cor */
  width: auto;
  height: 100%;
  max-height: 160px;
}

.style_whatMerxOffer__31hKV img {
  grid-area: imagem;
}
.style_whatMerxOffer__31hKV h3 {
  grid-area: titulo;
}

.style_whatMerxOffer__31hKV p {
  grid-area: paragrafo;
}
.style_whatMerxOffer__31hKV a {
  grid-area: botao;
}

@media screen and (max-width: 1000px) {
  .style_textOffer__3WV4t {
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .style_whatMerxOffer__31hKV {
    display: grid;
    grid-template-areas:
      "autoproducao autoproducao"
      "parcerias  projetos "
      "mercadoLivre consultoria";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    grid-gap: 10px;
    gap: 10px;
    color: white;
    margin: 10px auto 0;
    max-width: 1200px;
  }

  .style_autoproducao__1Sq__ img {
    width: 100%;
  }

  .style_mercadoLivre__3YqWW img,
  .style_consultoria__17j6Q img {
    height: 100px;
    width: auto;
  }
  .style_mercadoLivre__3YqWW div:nth-child(1),
  .style_consultoria__17j6Q div:nth-child(1) {
    width: 100%;
    order: 2;
  }
  .style_exploreAndImageCol__23bDC {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .style_autoproducao__1Sq__ img {
    width: 100%;
  }
  .style_autoproducao__1Sq__ div:nth-child(1) {
    width: 60%;
  }

  .style_autoproducao__1Sq__ img {
    width: 100%;
    max-width: 200px;
  }
  .style_mercadoLivre__3YqWW img,
  .style_consultoria__17j6Q img,
  .style_parcerias__CqBTg img,
  .style_projetos__1dpBs img {
    padding: 10px 0;
    max-height: 100px;
    width: auto;
  }

  .style_totalContent__1THtE p {
    margin-left: 0;
    margin-top: 5px;
  }

  .style_totalContent__1THtE button {
    margin-top: 10px;
  }

  .style_whatMerxOffer__31hKV button,
  .style_projetos__1dpBs button {
    order: 1;
  }
  .style_parcerias__CqBTg,
  .style_projetos__1dpBs,
  .style_consultoria__17j6Q,
  .style_mercadoLivre__3YqWW {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      "imagem"
      "titulo"
      "paragrafo"
      "botao";
  }

  .style_autoproducao__1Sq__ {
    grid-template-columns: auto auto;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "titulo imagem"
      "paragrafo imagem"
      "botao imagem";
    justify-content: space-evenly;
    padding: 10px;
  }
  .style_autoproducao__1Sq__ button {
    max-height: 40px;
  }
  .style_autoproducao__1Sq__ h3,
  .style_autoproducao__1Sq__ p,
  .style_autoproducao__1Sq__ button {
    max-width: 350px;
  }
}

@media screen and (max-width: 550px) {
  .style_autoproducao__1Sq__ {
    grid-template-columns: auto;
    grid-template-rows: 1fr repeat(3, auto);
    grid-template-areas:
      "imagem"
      "titulo"
      "paragrafo"
      "botao";
    justify-content: center;
    width: 100%;
  }
  .style_autoproducao__1Sq__ h3,
  .style_autoproducao__1Sq__ p,
  .style_autoproducao__1Sq__ button {
    max-width: 100%;
  }
  .style_autoproducao__1Sq__ img {
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  .style_whatMerxOffer__31hKV {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    grid-template-areas:
      "autoproducao"
      "parcerias"
      "projetos"
      "mercadoLivre"
      "consultoria";
  }
  .style_whatMerxOffer__31hKV img {
    justify-self: center;
    margin: 0 auto;
  }
  .style_whatMerxOffer__31hKV div {
    margin: 0 auto;
    width: 100%;
  }
  .style_autoproducao__1Sq__ .style_exploreAndImageCol__23bDC {
    order: -1;
  }
  .style_autoproducao__1Sq__ {
    flex-direction: column;
    width: 100%;
  }
  .style_autoproducao__1Sq__ div:nth-child(1) {
    width: 100%;
  }
}

.style_footer__2VhfU {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #f9f9f9;
}
.style_footer__2VhfU h2,
.style_footer__2VhfU p,
.style_footer__2VhfU button,
.style_footer__2VhfU span,
.style_footer__2VhfU a {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0;
}
.style_footer__2VhfU .style_totalContent__2iOlY {
  width: 100%;
  max-width: 1380px;
  margin: 10px auto;
  display: flex;
  padding: 20px 40px;

  grid-gap: 20px;

  gap: 20px;
}

.style_logo__3R7aK {
  max-width: 450px;
}

.style_logo__3R7aK p {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.8);
}

.style_totalContent__2iOlY a:hover {
  text-decoration: none;
}

.style_menus__2OrAF {
  display: flex;
  grid-gap: 20px;
  gap: 20px;

  justify-content: center;
}

.style_menu__2tkQX {
  display: flex;
  flex-direction: column;

  min-width: 200px;
  max-width: 300px;
  grid-gap: 5px;
  gap: 5px;
}

.style_menu__2tkQX h2 {
  font-size: 0.9em;
  font-weight: bold;
  margin: 5px 0;
}

.style_menu__2tkQX a {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  font-size: 0.8em;
}

.style_menu__2tkQX a:hover {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 1070px) {
}

@media screen and (max-width: 910px) {
  .style_menus__2OrAF {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .style_totalContent__2iOlY {
    flex-direction: column;
  }
}

/* Estilos base */
.style_content__1lR2X p,
.style_content__1lR2X h2,
.style_content__1lR2X a {
  font-family: "Inter", sans-serif;
}

.style_allContent__3rxqb {
  width: 100%;
}

.style_divDepoiments__2oYfq {
  text-align: center;
  margin: 0 auto;
}

.style_subtitle__1NWVg {
  max-width: 800px;
  margin: 0 auto;
}

.style_depoiments__3PKNC {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  overflow: auto;
  margin-top: 10px;
  padding: 5px 0;
}

.style_content__1lR2X .style_depoiment__12sPz {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  background-color: #000d20;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  min-width: 320px;
}

.style_depoiment__12sPz ::-webkit-scrollbar {
  width: 12px; /* Largura da barra de rolagem */
}

.style_depoiment__12sPz ::-webkit-scrollbar-track {
  background: red; /* Cor do fundo da barra de rolagem */
}

.style_depoiment__12sPz ::-webkit-scrollbar-thumb {
  background: #3269ba; /* Cor do polegar (scroll) */
  border-radius: 10px; /* Arredondamento */
}

.style_depoiment__12sPz ::-webkit-scrollbar-thumb:hover {
  background: #285a9e; /* Cor do polegar ao passar o mouse */
}

.style_depoiment__12sPz img {
  width: 100%;
  max-width: 80px;
}
.style_textsDepoiment__24IIu {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.style_textsDepoiment__24IIu h3 {
  font-size: 1.3em;
}
.style_content__1lR2X .style_textsDepoiment__24IIu p {
  font-size: 0.9em;
  color: white;
  font-weight: 500;
  margin: 0;
}

.style_content__1lR2X {
  max-width: 1380px;
  padding: 20px;
  margin: 0px auto;
}

.style_texts__1_QEa {
  width: 60%;
  margin: auto 0;
}

.style_texts__1_QEa p {
  max-width: 100%;
}
.style_manifest__1A8qP {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}

.style_video__1hTNF .style_videoToWatch__1tHp5 {
  width: 100%;
  border-radius: 8px;
  aspect-ratio: 16/9;
  object-fit: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.style_video__1hTNF .style_content__1lR2X h2 {
  font-weight: bold;
  color: #000;
  font-size: 2em;
}

.style_content__1lR2X p {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 18px; /* Tamanho base */
}

@media screen and (max-width: 1050px) {
  .style_manifest__1A8qP {
    flex-direction: column;
  }
  .style_texts__1_QEa {
    order: 1;
    width: 100%;
  }
  .style_texts__1_QEa h2 {
    text-align: center;
  }
  .style_texts__1_QEa p {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
  }
}

/* Responsividade para fontes */
@media (max-width: 1200px) {
  .style_content__1lR2X h2 {
    font-size: 28px; /* Reduz um pouco o tamanho */
  }

  .style_content__1lR2X p {
    font-size: 16px;
  }
  .style_content__1lR2X .style_knowMore__2q1Kn {
    margin: 10px auto;
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .style_content__1lR2X h2 {
    font-size: 24px; /* Texto menor para dispositivos médios */
  }

  .style_content__1lR2X p {
    font-size: 14px;
  }

  .style_video__1hTNF img:nth-child(2) {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .style_content__1lR2X .style_depoiment__12sPz {
    max-width: 280px;
    min-width: 280px;
  }

  .style_content__1lR2X h2 {
    font-size: 20px; /* Texto ainda menor para telas pequenas */
  }

  .style_content__1lR2X p {
    font-size: 12px; /* Texto legível, mas compacto */
  }
}

.style_video__1hTNF {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.style_video__1hTNF img:nth-child(1) {
  width: 100%;
  max-width: 1000px;
}

.style_video__1hTNF .style_playButton__2yqhd {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.style_knowMore__2q1Kn {
  margin: 30px 0;
  width: 100%;
  display: block;
  max-width: 90%;
  background-color: #000d20;
  color: white;
  font-weight: 500;
  padding: 8px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
}

.style_knowMore__2q1Kn:hover {
  color: #e0e0e0;
  text-decoration: none;
  transform: scale(1.03);
  transition: 0.3s ease-in-out;
}

.style_content__2DarO p,
.style_content__2DarO h2 {
  font-family: "Inter", sans-serif;
}

.style_content__2DarO h2 {
  font-weight: bold;
  color: white;
}

.style_allContent__3fCjT {
  width: 100%;

  background-color: #000d20;
}

.style_content__2DarO {
  max-width: 1380px;
  padding: 20px 0;
  margin: 10px auto;
  text-align: center;
}
.style_advantages__PUrNk {
  margin: 40px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}

.style_totalBanner__2ZRf8 {
  display: flex;
}

.style_advantage__3-XVi {
  background: linear-gradient(to right, #363636 30%, #114796 100%);
  color: white;
  padding: 10px 20px;
  border: 3px solid #3271cf;
  border-radius: 5px;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.style_advantage__3-XVi h3 {
  font-size: 1.2em;
  margin-top: 20px;
  font-weight: bold;
}

.style_advantage__3-XVi:nth-child(1) img {
  max-width: 120px;
}

.style_advantage__3-XVi:nth-child(1) h3 {
  margin-top: 40px;
}

.style_advantage__3-XVi:nth-child(2) {
  margin: 0;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.style_advantage__3-XVi img {
  width: 100%;
  max-width: 80px;
}
.style_advantage__3-XVi p {
  font-weight: 600;
  font-size: 0.9em;
}

@media screen and (max-width: 768px) {
  .style_advantages__PUrNk {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin: 10px;
  }
  .style_advantage__3-XVi {
    margin: 0;
    max-width: 350px;
  }
  .style_content__2DarO h2 {
    font-size: 1.8em;
    margin: 10px;
  }
  .style_content__2DarO {
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  .style_advantage__3-XVi {
    padding: 10px;
  }
}

/* Estilos base */
.style_content__zOZq6 p,
.style_content__zOZq6 h2,
.style_content__zOZq6 a,
.style_content__zOZq6 h3 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.style_content__zOZq6 p {
  margin-left: 0;
}

.style_content__zOZq6 h2:nth-child(1) {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  color: #000d20;
}
.style_content__zOZq6 .style_advantageP__3Ihs2 {
  margin-top: 5px;
  color: rgba(0, 13, 32, 0.5);
  text-align: center;
}
.style_allContent__3vrYf {
  width: 100%;
  background-color: #f9f9f9;
}

.style_allAdvantages__3sDWX .style_titleAdvantage__2ni2E {
  color: #000d20;
  font-size: 2.6em;
}

.style_content__zOZq6 {
  max-width: 1380px;
  padding: 20px;
  margin: 10px auto;

  color: white;
}

.style_steps__3O1UF {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
}

.style_step__1L_ET {
  background: linear-gradient(to right, #363636 30%, #114796 100%);
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  max-width: 320px;
}

.style_step__1L_ET h4 {
  margin-top: 10px;
  margin-left: 5px;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
}

.style_step__1L_ET p {
  font-size: 0.8em;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: 400;
}

.style_step__1L_ET img {
  width: 100%;
  align-self: flex-end;
}

.style_step__1L_ET h4,
.style_step__1L_ET p {
  padding: 5px;
}
.style_knowMore__Eiu-S {
  width: 80%;
  display: block;

  color: #fff;
  background: linear-gradient(to right, #363636 30%, #114796 100%);
  font-weight: 500;
  padding: 8px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;

  justify-self: flex-end;
}

.style_knowMore__Eiu-S:hover {
  color: #e0e0e0;
  text-decoration: none;
  transform: scale(1.03);
  transition: 0.3s ease-in-out;
}

.style_advantages__1M-HM {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
}

.style_textAdvantages__24Q3q {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 600px;
}

.style_allAdvantages__3sDWX {
  margin-top: 40px;
}

.style_textAdvantages__24Q3q h3 {
  font-size: 2.4em;
}
.style_textAdvantages__24Q3q .style_firstAdvantage__3JyKq {
  margin-top: 30px !important;
  max-width: 90%;
  color: rgba(0, 13, 32, 1);
}

.style_secondAdvantage__13egc {
  color: rgba(0, 13, 32, 1);
}

.style_partnerAdvantages__1E2um {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}

.style_advantage__Lu4At {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #363636 30%, #114796 100%);
  padding: 20px 10px;
  border-radius: 5px;
  width: 49%;
  max-width: 320px;
  border: 1px solid #363636;
}

.style_advantage__Lu4At span {
  width: 50px;
  height: 50px;
  padding: 3px;
  border: 2px solid #0088ff;
  border-radius: 100%;
  position: relative;
}

.style_advantage__Lu4At span img {
  max-width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
}

.style_advantage__Lu4At h3 {
  font-size: 1.2em;
  text-align: start;
  margin-top: 5px;
}

.style_advantage__Lu4At p {
  font-size: 0.9em;
  font-weight: 500;
}

@media screen and (max-width: 1000px) {
  .style_partnerAdvantages__1E2um {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .style_content__zOZq6 .style_advantageP__3Ihs2 {
    font-size: 0.9em;
  }
  .style_advantage__Lu4At {
    width: 40%;
    max-width: 350px;
  }
  .style_steps__3O1UF {
    flex-wrap: wrap;
    justify-content: center;
  }
  .style_step__1L_ET {
    max-width: 300px;
  }
  .style_advantages__1M-HM {
    justify-content: center;
  }

  .style_titleAdvantage__2ni2E {
    font-size: 2em !important 
    ;
  }
  .style_textAdvantages__24Q3q p,
  .style_textAdvantages__24Q3q a {
    display: none;
  }
  .style_textAdvantages__24Q3q,
  .style_textAdvantages__24Q3q h3 {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .style_advantages__1M-HM {
    grid-gap: 10px;
    gap: 10px;
  }
  .style_advantage__Lu4At {
    width: 45% !important;
  }
}

@media screen and (max-width: 1200px) {
  .style_advantage__Lu4At {
    width: 45%;
  }
}

@media screen and (max-width: 600px) {
  .style_content__zOZq6 {
    padding: 10px;
  }
  .style_advantage__Lu4At {
    width: 100%;
  }
  .style_advantage__Lu4At {
    width: 100% !important;
    max-width: 350px;
  }
  .style_allAdvantages__3sDWX .style_titleAdvantage__2ni2E,
  .style_content__zOZq6 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 660px) {
  .style_step__1L_ET {
    width: 100%;
    max-width: 350px !important;
  }
}

/* Estilos base */

.style_content__1CrkY {
  max-width: 1380px;
  padding: 20px;
  margin: 10px auto;
  color: white;
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}

.style_content__1CrkY p,
.style_content__1CrkY h2,
.style_content__1CrkY a,
.style_content__1CrkY h3,
.style_content__1CrkY h4 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.style_content__1CrkY p {
  margin-left: 0;
}

.style_content__1CrkY h2 {
  max-width: 400px;
  font-weight: bold;
  font-size: 2.2em;
  color: #3271cf;
}
.style_allContent__3KgaF {
  width: 100%;
  background-color: #f6f6f6;
}

.style_texts__epzRO {
  margin-top: 40px;
  width: 50%;
}

.style_img__GMwX8 img {
  width: 100%;
}

.style_texts__epzRO .style_revolution__21Le3 {
  color: rgba(50, 113, 207, 0.8) !important;
  font-weight: bold;
  margin: 0;
  font-size: 1.3em;
}

.style_texts__epzRO h3 {
  color: rgba(50, 113, 207, 0.8);
  font-size: 1.4em;
}

.style_questions__1Wgug {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  max-width: 500px;
}

.style_question__2dIF2 h4 {
  color: #3271cf;
  font-size: 1.2em;
}

.style_question__2dIF2 p {
  color: rgba(50, 113, 207, 0.8);
}

.style_lastQuestion__Fh9T- {
  margin-top: 50px;
}

.style_arrow__141v4 {
  margin: 40px auto 0px;
  max-width: 70px;
  display: block;
}

@media screen and (max-width: 1000px) {
  .style_content__1CrkY {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 0;
    gap: 0;
  }
  .style_content__1CrkY h2 {
    font-size: 2em;
  }
  .style_img__GMwX8 img {
    max-width: 500px;
  }
  .style_texts__epzRO {
    width: 100%;
    max-width: 700px;
  }
}

/* Estilos base */

.style_allContent__2x95d {
  width: 100%;
  margin: 20px 0;
  background-color: #000d20;
}

.style_allContent__2x95d h2 {
  text-align: center;
  padding: 20px 0;
  color: white;
  font-weight: bold;
  font-size: 2em !important;
}
.style_content__1U8UC {
  max-width: 1380px;
  padding: 40px 70px;
  margin: 10px auto;
  color: white;
  display: flex;
  grid-gap: 100px;
  gap: 100px;
  flex-direction: column;
}

.style_content__1U8UC p,
.style_content__1U8UC h2,
.style_content__1U8UC a,
.style_content__1U8UC h3,
.style_content__1U8UC h4 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.style_benefit__3dQ9T {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.style_benefit__3dQ9T .style_texts__2vdLY {
  max-width: 500px;
}
.style_advantageImg__VJtrK {
  position: relative;
}

.style_advantageImg__VJtrK img {
  z-index: 2;
}

.style_advantageImg__VJtrK span {
  width: 100%;
  max-width: 400px;
  height: 200px;
  background: linear-gradient(to right, #363636 30%, #114796 100%);
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 60%;
  top: 65%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.style_benefit__3dQ9T img {
  max-width: 400px;
  border-radius: 5px;
  z-index: 2;
  position: relative;
}

.style_benefitOdd__1aGaP span {
  left: 40%;
  top: 65%;
  transform: translate(-50%, -50%);
}
.style_benefitOdd__1aGaP .style_texts__2vdLY {
  order: 1;
}

.style_benefit__3dQ9T div h3 {
  font-size: 1.2em;
  font-weight: 500 !important;
}

.style_benefit__3dQ9T div p {
  font-weight: 400;
  margin: 0;
}

@media screen and (max-width: 1000px) {
  .style_content__1U8UC {
    padding: 20px;
  }
  .style_allContent__2x95d h2 {
    font-size: 1.5em !important;
  }
  .style_advantageImg__VJtrK span {
    display: none;
  }
  .style_benefit__3dQ9T {
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    max-width: 500px;
    margin: 0 auto;
  }
  .style_benefit__3dQ9T img {
    width: 100%;
    max-width: 500px;
    border: 3px solid #114796;
  }
  .style_benefit__3dQ9T div {
    order: 1;
    width: 100%;
    max-width: 500px;
  }

  .style_benefit__3dQ9T .style_texts__2vdLY {
    order: 2;
  }
}


.headerDefault-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  align-items: center;

  text-align: center;
}

#autoprodutorId.headerDefault-row,
#mercadoLivreId.headerDefault-row {
  margin: 0;
}

.headerDefault-column {
  display: "flex";
  flex-direction: "column";
  margin: "50px";

  width: 600px;
}

.imgSize-autoproducao {
  width: 50%;
}

.headerDefault-img {
  margin: auto 0;
  width: 40%;
}

.title-ml-header {
  color: #0067cc;
  font-weight: bold;
  font-size: 44px;
  letter-spacing: 0.02em;
}

.subtitle-ml-header {
  margin-top: 30px;
  font-size: 18px;
  letter-spacing: 0.05em;
}

@media only screen and (max-width: 1100px) {
  .headerDefault-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .headerDefault-img {
    width: 400px;
    text-align: center;
  }

  .headerDefault-column {
    width: 100%;
    padding: 30px;
    margin: 0px;
    width: 100%;
  }
}

.titleBox-modernizacao{
    width: 400px;
    position: absolute;
    left: 133px;
    margin-bottom: 50px;
}

.title-modenizacao{
    font-weight: bold;
    font-size: 56px;
    line-height: 68px;
    /* or 121% */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
}
.subtitle-modernizacao{
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
}

.textBox-modernizacao{
    padding: 150px;
    padding-bottom: 50px
}

.text-modernizacao{
    padding: 50px;
    padding-bottom: 0px;
    font-size: 21px;
    line-height: 30px;
    /* or 143% */

    letter-spacing: -0.015em;

    color: rgba(0, 0, 0, 0.8);
}

.destak{
    text-decoration: underline;
}

.container-modernizacao{
    padding-top: 80px;
}

@media only screen and (max-width: 1100px){
    .titleBox-modernizacao{
        width: 200px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    .container-modernizacao{
        position: relative;
    }

    .title-modenizacao{
        font-size: 32px;
        line-height: 32px;
    }

    .subtitle-modernizacao{
        font-size: 18px;
    }

    .textBox-modernizacao{
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 50px;
    }

    .table-title{
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
        /* identical to box height, or 180% */

        text-align: center;

        color: #515151;
    }

    .table-item{
        font-size: 14px;
    }

    .container-table{
        border-style: solid;
        padding: 5px 5px 5px 5px;
        margin-bottom: 50px;
        font-size: 16px;
        line-height: 30px;
    }

    .text-modernizacao{
        padding: 0px;
    }

    .container-modernizacao{
        padding-top: 30px;
    }
}
.container-video {
  position: relative;
  background-color: #e5e5e5;
}

.video-box {
  position: relative;
  text-align: center;
  padding: 70px;
  margin-top: 50px;
}

.video-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-60%);
  color: rgb(164, 207, 250);
  font-size: 120px;
  cursor: pointer;
  transition: all 0.7s linear;
}

.video-button:hover {
  color: rgb(95, 175, 255);
}

.center-div-box {
  text-align: center;
}

.imgVideo {
  height: 80vh;
}

@media only screen and (max-width: 1100px) {
  .imgVideo {
    width: 100%;
    height: 100%;
  }

  .video-box {
    padding: 15px 0px 15px 0px;
  }

  .video-button {
    font-size: 60px;
  }
}

.table-container{
    background-color: #E5E5E5;
}

.textBox-table{
    padding: 50px 150px 50px 150px;
    font-size: 21px;
    line-height: 30px;
}

.text-table{
    padding: 0px 50px 0px 50px;
    text-align: justify;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -0.015em;

    color: rgba(0, 0, 0, 0.8);
}

.table-box{
    padding: 0px 400px 0px 400px;
}

.table-column-ml{
    display: flex;
    flex-direction: column;
}

.table-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #CFE7FF;
    margin: 10px 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    line-height: 4px;
    padding: 0px 15px;
}

.table-item{
    text-align: center;
    font-weight: bold;
    width: 100px;
    margin: 0px;
}
.table-item-title{
    width: 300px;
    margin: 0px
}

@media only screen and (max-width: 1100px){
    .textBox-table{
        padding: 0px;
        font-size: 16px;
        line-height: 20px;
    }
    .table-container{
        padding: 30px 0 30px 0;
    }
    .table-box{
        padding: 30px;
    }
}
.container-schema {
  margin: 50px 0 50px 0;
  background-color: #e5e5e5;
}

.textbox-schema {
  padding: 100px 200px 100px 200px;
  text-align: justify;
}

.schema-container {
  text-align: center;
}

.subtitle-schema-container {
  font-size: 18px;
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
}

.images-schema-container {
  margin-top: 100px;
  display: flex;
}

.image-content {
  flex: 20% 1;
  padding: 5px;
  position: relative;
}

.arrow-content {
  position: relative;
}

.iconSize-schema {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 100px;
  font-weight: 200;
  color: #2f80ed;
}

.aboveImg {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.my-row {
  display: flex;
}

.my-column {
  flex: 50% 1;
  position: relative;
}

.top-left {
  transform: rotateZ(-150deg);
}

.top-right {
  transform: rotateZ(-30deg);
}

.mgTOP {
  margin-top: 100px;
}

.schemaContainer {
  text-align: center;
  padding-bottom: 50px;
}

.imgSchema {
  height: 90vh;
  padding: 50px;
}

@media only screen and (max-width: 1100px) {
  .textbox-schema {
    padding: 30px;
    text-align: justify;
  }
  .imgSchema {
    width: 100%;
    height: 100%;
    padding: 20px 0;
  }
  .schemaContainer {
  }
}

.container-vantagens{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.title-box-vantagens{
    width: 15%;
    margin-left: 10%;
}

.title-vantagens{
    font-weight: bold;
    font-size: 56px;
    line-height: 68px;
    
}

.subtitle-vantagens{
    font-size: 18px;
    line-height: 30px;
}

.container-table{
    padding-top: 60px;
    text-align: center;
}

.table-title-a{
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    padding: 5px;
}

.table-item{
    font-size: 16px;
    line-height: 28px;
}

.linha{
    display: inline;
}

.centerCol{
    display: inline-block;
}

.line-vant{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

}

.col-vant{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
}
.textbox-vant{
    padding: 100px 200px 0 200px;
    text-align: justify;
    font-size: 21px;
}

.title3{
    text-align: center;
    margin-bottom: 20px;
}

.title32{
    font-size: 26px;
    
}

@media only screen and (max-width: 1100px){
    .title-box-vantagens{
        width: 100%;
        margin-left: 0px;
        padding: 30px;
    }
    .table-title{
        border-bottom-style: solid;
    }
    .line-vant{
        align-items: center;
    }
    .table-item{
        padding: 5px;
    }

    .border-left{
        border-left-style: solid;
    }

    .border-right{
        border-left-style: solid;
    }

    .title-vantagens{
        font-weight: bold;
        font-size: 44px;
        line-height: 68px;
    }

    .subtitle-vantagens{
        font-size: 22px;
        line-height: 30px;
    }

    .textbox-vant{
        padding: 23px;
        text-align: justify;
        font-size: 16px;
    }
    .title32{
        font-size: 22px;
        
    }

}
.container-autoproducao{
    margin: 0;
    font-family: 'Encode Sans', sans-serif;
    width: 100%;
    overflow: hidden;
}
.container-mercadoLivre{
    margin: 0;
    font-family: 'Encode Sans', sans-serif;
    width: 100%;
    overflow: hidden;
}
.container-register42{
    margin: 0;
    font-family: 'Encode Sans', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 50px;
    
        
}

.register-box42{
    background-color: #E5E5E5;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    display: flex;

}

.register-cell{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    text-align: center;
}

.registerIcon{
    font-size: 30px;
    margin-right: 15px;
    transform: translateY(17%);
}

.check-box-cont{
    text-align: center;
    padding: 40px;
}

.register-button{
    margin: 0px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-weight: normal;
    text-align: center;
    width:360px;
    background:#0067CC;
    color:#FFFFFF;
    border: none;
    cursor:pointer;
    display:flex;
    font-size: 16px;
}

.register-button:link,
.register-button:visited{
    color: rgb(255, 255, 255);
    padding: 10px;
    text-decoration: none;
    display: inline-block;
    transition: all .5s;
    position: relative;
}

.register-button:hover {
    background-color: #0067CC;
    border: white;
    color: white;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); 
}


.register-button::after{
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #0067CC;
    transition: all .4s;
}

.register-button:hover::after{
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}


@media only screen and (max-width: 1200px){
    .container-register42{
        padding: 0px;
    }
}
.modal-body {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /*
    height: 645px;
    text-align: center;
    display: flex;
    align-items: center;
    */
  font-family: Encode Sans;
  padding: 0px;

  background-color: white;

  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.container-modal {
  padding: 0px;
}

.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.spinnerLogin {
}

.externalSpinnerLogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px 0px;
}

.close-modal {
  position: absolute;
  left: 100%;
  transform: translateX(-100%);
  text-decoration: none;
  font-size: 20px;
  color: black;
}

.fb-button {
  width: 268px;
  padding: 5px 15px 5px 15px;
  font-weight: bold;
  background-color: #003365;
  color: white;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.015em;
  margin-left: 50%;
  transform: translateX(-50%);
  border-style: none;
  border-radius: 4px;
}

.fb-button:hover {
  background-color: #001365;
}

.input-login {
  width: 268px;
  border-radius: 4px;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
}

.center-input {
  margin-left: 50%;
  transform: translateX(-50%);
}

.divide-text {
  margin: 0 0 15px 0;
}

.color-change {
  background-color: #003365;
}

.loginTitle {
  font-size: 26px;
  letter-spacing: -0.015em;
  color: #404040;
  text-align: center;
}

.loginText {
  letter-spacing: -0.015em;
  font-size: 12px;
  text-align: center;
}

.login-box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.logo-login {
  width: 111px;
}

.close-modal:hover {
  text-decoration: none;
}

.close-icon {
  font-size: 30px;
  position: absolute;
  left: 100%;
  top: 0px;
  transform: translateX(-200%) translateY(50%);
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
}

.text-terms{
    text-align: justify;
    margin-top: 20px;
}

.container-modal-terms{
    text-align: center;
}


.buttonToHome{
    font-size: 18px;
    background-color: #003365;
    color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 4px;
    font-weight: 600;
    transition: all 0.3s linear;
    width: 50%;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    margin-bottom: 30px;
}

.buttonToHome:hover{
    text-decoration: none;
    color: white;
    background-color: #01509e;
    
}

.container-modalFinish{
    text-align: center;
}

.okay-icon{
    color: green;
    font-size: 92px;
    margin-left: 50%;
    transform: translateX(-50%);
}

.checkEntrar{
    display: flex;
    flex-direction: column;
    position: relative;
}

.loginTitleCheck{
    font-size: 24px;
}

.loginTitleChecksub{
    font-size: 18px;
}
.container-formSimulacao-master {
  /* background-color: #f5f5f5; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 50px;
  font-family: "Encode Sans", sans-serif;
}

.container-formSimulacao {
  padding: 20px;
  width: 100%;
}

.border_for_debug {
  border: 1px solid gray;
}

.select-chart {
  border: 0px;
  cursor: pointer;
  background-color: #eaeaea;
  outline: 0;
  padding: 16px 8px;
  width: 300px;
  position: relative;
  border-radius: 8px;
}

.select-chart-area {
  margin-left: 28px;
  margin-bottom: 40px;
  display: flex;
}

.title-choose {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.formLine {
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: start;
  grid-gap: 0px 30px;
  gap: 0px 30px;
}

.formLine-data {
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(auto-fill, minmax(299px, 1fr));
  justify-items: start;
  grid-gap: 20px 20px;
  gap: 20px 20px;
  margin: 30px 0;
}

.simulacao-buttons {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 40px;
}

.simulator-vpl {
  margin: 60px 0;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  width: 100%;
  white-space: nowrap;
  font-weight: 400;
  overflow: hidden;
  text-overflow: clip;
}

@media (max-width: 1000px) {
  .simulacao-buttons {
    flex-direction: column;
    width: 100%;
    margin: 20px auto;
    grid-gap: 20px;
    gap: 20px;
    max-width: 500px;
  }

  .formLine {
    justify-items: center;
  }
}

span.input-group-text {
  font-family: "Roboto";
  font-weight: 400;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.simulacao-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.simulacao-header span {
  margin: 0 40px 0 6px;
  color: #0072ce;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 16px;
}

.simulacao-header-title {
  margin: 40px 0;
}

.simulacao-header-title h1 {
  color: #212322;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 42px;
}

.simulacao-header-title h2 {
  color: #7e7e82;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
}

.simulacao-header-title h3 {
  color: #212322;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 32px;
}

.error-desconto {
  color: red;
}

hr {
  margin: 4px 0 16px 0 !important;
}

.simulacao-voltar {
  cursor: pointer;
}

.simulacao-error {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.simulacao-form {
  margin-top: 20px;
}

.simulacao-submit {
  margin: 0 auto !important;
  width: 290px;
}

.simulacao-submit:disabled {
  cursor: not-allowed;
}

.simulacao-dados-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.simulacao-dados-left h1 {
  margin: 0 6px;
  color: #0072ce;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 16px;
}

.simulacao-dados-left span {
  color: #212322;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  text-transform: uppercase;
}

.simulacao-editar-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 0;
  border-radius: 8px;
}

.simulacao-dados-button {
  margin: 0 6px;
  color: #0072ce;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 16px;
}

.custom-control-input ~ .custom-control-label::before {
  border-color: #d9d9d9 !important;
  background-color: #d9d9d9 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #d9d9d9 !important;
  background-color: #d9d9d9 !important;
}

.custom-switch .custom-control-label::after {
  background-color: #f91010 !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #1fa824 !important;
}

.simulacao-button {
  background-color: white;
  color: #0072ce;
  border: 1px solid #0072ce;

  font-family: "Roboto";
  font-weight: 300;
  font-size: 16px;

  text-align: center;
  cursor: pointer;

  margin: auto 0px;
  padding: 17px 38px;
}

.simulacao-button:hover {
  background-color: #f1f1f1;
}

.simulacao-button-selected {
  background-color: #0072ce;
  color: white;
  border: 1px solid #0072ce;

  font-family: "Roboto";
  font-weight: 300;
  font-size: 16px;

  text-align: center;
  cursor: pointer;

  margin: auto 0px;
  padding: 17px 38px;
}

button:focus {
  outline: 0 !important;
}

.simulacao-form-header {
  color: #7e7e82;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 24px;
}

.simulacao-form-title {
  color: #7e7e82;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
}

.simulacao-form-title span {
  color: #f91010;
}

.simulacao-form-description {
  color: #212322;
  font-size: 16px;
  font-family: Roboto;
}

.simulacao-data-flex {
  display: flex;
  flex-direction: column;
}

.simulacao-accordion-container {
  padding: 20px;
  background-color: #f5f5f5;
}

.dados-title {
  margin-bottom: 30px;
  font-weight: bold;
}

.table-tarifa-container {
  width: 100%;
}

.containerResSimulMaster {
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
}

.containerResSimul {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
}

@media (max-width: 1024px) {
  .containerResSimulMaster {
    flex-direction: column;
  }

  .containerResSimul {
    width: 100%;
  }
}

.bandeirasDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
}

.bandeirasTitle {
  width: 100%;
  max-width: 350px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 24px;
}

.bandeiraCinza {
  display: flex;
  align-items: center;
  margin: auto;
  width: 50%;
  height: 8px;
  background-color: #dee2e6;
  border-radius: 16px;
}

.bandeiraAzul {
  display: flex;
  align-items: center;
  margin: auto;
  width: 50%;
  height: 8px;
  background-color: rgb(0, 103, 204);
  border-radius: 16px;
}

.bandeiraVerde {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 50%;
  height: 8px;
  background-color: #1fa824;
  border-radius: 16px;
}

.bandeiraAmarela {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 50%;
  height: 8px;
  background-color: rgb(211, 211, 16);
  border-radius: 16px;
}

.bandeiraVermelhaP1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 50%;
  height: 8px;
  background-color: rgb(211, 65, 16);
  border-radius: 16px;
}

.bandeiraVermelhaP2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 50%;
  height: 8px;
  background-color: rgb(161, 47, 9);
  border-radius: 16px;
}

.resultadoSimulacaoContainer {
  font-family: "Encode Sans", sans-serif;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 20px 15px;
  gap: 20px 15px;
}

.resultadoSimulacaoContainer2 {
  font-family: "Encode Sans", sans-serif;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 10px 10px;
  gap: 10px 10px;
}

@media (max-width: 900px) {
  .resultadoSimulacaoContainer,
  .resultadoSimulacaoContainer2 {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(auto-fill, minmax(299px, 1fr));
  }
}

@media (max-width: 780px) {
  .resultadoSimulacaoContainer,
  .resultadoSimulacaoContainer2 {
    justify-items: center;
  }

  .select-chart-area {
    display: flex;
    flex-direction: column;
  }

  .title-choose {
    font-size: 14px;
  }
}

.send-invoice-area {
  display: flex;
  margin: 0 auto;
}

.headerResultadoSimulacao {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1200px;
}
.titleResultadoSimulacao {
  font-size: 44px;
  font-weight: bold;
  color: #646464;
}

.buttonPDF {
  background-color: #0067cc;
  color: white;
  font-weight: bold;
  border: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
  border-radius: 4px;
  padding: 5px 30px;
  transition: all 0.4s linear;
}

.buttonPDF:hover {
  transform: translateX(-50%) translateY(-5px);
  box-shadow: 0px 5px 7px 1px rgba(0, 0, 0, 0.75);
}

.headerLeft {
  display: flex;
  flex-direction: column;
}

.headerRight {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.logoSizeResultado {
  width: 89px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.headerLeftContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
}

.simulacaoName {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.headerLeftContentTitle {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-align: left;
}

.headerLeftContentSubTitle {
  font-size: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  line-height: 5px;
}

.headerRightContentTitle {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;

  letter-spacing: 0.1em;
}
.headerRightContentSubTitle {
  font-size: 16px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
}

.simulacao-img {
  height: 100px;
}

.interTitle {
  margin-top: 110px;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.01em;
}

.economia-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 32px;
  gap: 32px;
  margin: 32px 0;
}

.economia-card {
  background-color: #f5f5f5;
  width: 100%;
  max-width: 350px;
  padding: 16px;
  border-radius: 8px;
}

.economia-card h1 {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 24px;
  color: #212322;
  text-transform: capitalize;
}

.economia-card h2 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 24px;
  color: #212322;
}

.economia-card h3 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 24px;
}

.economia-card2 {
  background-color: #f5f5f5;
  width: 100%;
  max-width: 280px;
  padding: 8px;
  border-radius: 8px;
}

.economia-card2 h1 {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 24px;
  color: #212322;
  text-transform: capitalize;
}

.economia-card2 h2 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 24px;
  color: #212322;
}

.economia-card2 h3 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 24px;
}

.economia-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.economia-positivo {
  color: #1fa824;
}

.economia-negativo {
  color: #f91010;
}

.ResSimulTableTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resultadoTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 20px 15px;
  gap: 20px 15px;
}

.containerResSimulTitle {
  font-size: 28px;
  font-weight: bold;
  color: #212322;
  font-family: "Roboto";
  text-align: center;
  border-bottom: 1px solid #ebeff2;
  padding: 10px 0;
}

.band-divis {
  display: flex;
  flex-direction: row;
  width: 200px;
}
.ResSimulTable {
  width: 172px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}

.ResSimulTableContent {
  font-family: "Roboto";
  text-align: center;
  width: 172px;
  font-size: 12px;
}

.ResSimulTableContentC {
  font-family: "Roboto";
  text-align: end;
  padding-right: 55px;
  width: 172px;
  font-size: 12px;
}

.ResSimulTableContentD {
  margin-bottom: 34px;
  width: 172px;
}

.ResSimulTableContentB {
  font-family: "Roboto";
  font-size: 12px;
  width: 80px;
  text-align: end;
}

.ResSimulTableContentA {
  font-family: "Roboto";
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-align: end;
}

.separarNum {
  width: 172px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.economia-box-line {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
}

.economia-box {
  width: 280px;
  height: 104px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  padding: 16px 24px;
  border-radius: 4px;
}
.ambLivreTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title-economia-box {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #ebeff2;
}

.economia-box-content-tent {
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
}

.economia-box-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rodapeResSimul {
  width: 1180px;
  height: 48px;
  border-radius: 4px;
  margin-top: 24px;

  line-height: 30px;
  margin-bottom: -24px;
}

.rodapeResSimul-content {
  width: 1154px;
  height: 14px;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  line-height: 0px;
  color: #1c1c1c;
}

.rodapeResSimul-all {
  width: 1154px;
  height: 34px;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  line-height: 21px;
  color: #1c1c1c;
}

.ecoProjt {
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: #646464;
  margin: 48px 0px 0px 0px;
}

.eco-anual-projetada {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}

.graphEcoAcumu {
  display: flex;
  flex-direction: column;
  width: 580px;
  height: 408px;

  background: #ffffff;
  border-radius: 4px;
  padding: 22px 30px;
}

.pagTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  padding-bottom: 25px;
  border-bottom: 1px solid #eef0f7;
}

.energPrice {
  width: 580px;
  height: 194px;

  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px 24px 18px 24px;
}

.enerPriceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.energPriceT {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
}

.energPriceC {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #000000;
}

.energPriceC1 {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: end;
}

.sideGraph {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

.globalEconomy {
  width: 580px;
  height: 194px;

  background: #ffffff;
  border-radius: 4px;
  padding: 16px 24px 18px 24px;
}

.globalRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.CoisasGlobal {
  width: 380px;
  height: 76px;

  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
}

.CoisasGlobal-title {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
}

.CoisasGlobal-content {
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
  line-height: 0px;
}

.ecoGlobalValorPerc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.setSize {
  width: 200px;
}

.pricesComerci {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(128, 128, 128, 0.459);
  border-radius: 5px;
  padding: 5px;
  margin: 15px 0;
}

.pricesComerciLine {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.pricesComerciItem {
  text-align: center;
  width: 105px;
  font-size: 12px;
}

@media only screen and (max-width: 1100px) {
  .dados-title {
    margin-bottom: 30px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 950px) {
  .economia-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .send-invoice-area button {
    width: 55%;
    font-size: 13;
  }

  .send-invoice-area span {
    width: 55%;
    text-align: center;
    font-size: 13;
  }
}

@media (max-width: 500px) {
  span.simulacao-dados-button,
  div.simulacao-dados-left span,
  div.simulacao-dados-left h1 {
    font-size: 14px;
  }

  .ResSimulTableContentC {
    text-align: center;
    padding-right: 0;
  }
}

@media (min-width: 683px) and (max-width: 1200px) {
  .bandeirasDiv,
  .bandeirasTitle,
  .economia-card {
    max-width: 300px;
  }

  .bandeirasTitle {
    font-size: 16px;
  }
}

.economy-container {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  width: 1200px;
}

.container-simulacao-master {
  font-family: "Encode Sans", sans-serif;
  width: 100%;
  overflow: hidden;
}

.Consumo-input-container-master{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Roboto;

    
}

.Consumo-input-container{
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 1180px;
    
    
}

.Consumo-input-container-content{
    text-align: center;
    display: flex;
    flex-direction: column;
    
    
    
}

.logo-consumo-input{
    width: 150px;
    margin-left: 50%;
    transform: translateX(-50%);
}

.title-excel-input{
    font-family: Roboto;
    font-weight: bold;
    font-size: 42px;
    letter-spacing: 2px;
}

.input-container-excel{
    
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    font-family: Roboto;
    
    font-weight: bold;

    font-size: 16px;
}

.button-excel{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 20px 0px;
}

.dias-computados{
    display: flex;
    flex-direction: row;
    text-align: right;

    justify-content: space-evenly;
    width: 200px;
    margin: 15px 0px;
}

.dias-computados-match{
    display: flex;
    flex-direction: row;
    text-align: right;

    justify-content: space-evenly;
    width: 200px;
    margin: 15px 0px;
    margin-left: 50%;
    transform: translateX(-50%);
    border: 1px solid black;
    background-color: yellowgreen;

    font-weight: bold;
    color: black;
}

.dias-computados-1{
    display: flex;
    flex-direction: row;
    text-align: left;

    justify-content: space-evenly;
    width: 200px;
    margin: 15px 0px;
}

.dias-computados{
    margin-left: 50%;
    transform: translateX(-50%);
    border: 1px solid black;
    background-color: tomato;

    font-weight: bold;
    color: black;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.mainComponent {
  display: flex;
  width: 100%;
  min-height: 100vh; /* O componente sempre ocupará a altura total da tela */
}




.solicitarLogin {
  opacity: 0;
}

.ladoEsquerdo {
  width: 40%;
  padding: 60px 40px;
  background-image: url(/static/media/backgroundLadoEsquerdo.0b32ef70.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.trueChange{
  order: 2;
  
}


.ladoEsquerdo p,
.ladoEsquerdo h1,
.ladoDireito p,
.ladoDireitoh2,
.ladoDireito input,
.ladoDireito button,
.ladoDireito h3,
.ladoDireito span {
  font-family: "Poppins", sans-serif;
  margin: 0;
}
.ladoEsquerdo h1 {
  font-size: 2.6em;
  font-weight: bold;
  width: 70%;
  letter-spacing: 1px;
  max-width: 360px;
}
.ladoEsquerdo p:nth-child(2) {
  font-size: 1.3em;
  letter-spacing: 1px;
  width: 82%;
  margin-top: 30px;
  line-height: 25px;
  font-weight: 300;
  max-width: 560px;
}
.ladoEsquerdo img {
  width: 21%;
}
.ladoEsquerdo p:nth-child(3) {
  font-size: 0.9em;
}
.ladoDireito h3 {
  font-size: 1.8em;
  font-weight: bold;
}

.loginInputs {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.ladoEsquerdo b {
  font-weight: 700;
  letter-spacing: 1px;
}

.ladoDireito {
  width: 60%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.solicitarLogin {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.solicitarLogin p {
  color: #62636c;
  font-size: 0.9em;
}
.solicitarLogin button {
  padding: 5px 50px;
  border: 1px solid black;
  border-radius: 6px;
  background-color: transparent;
  font-weight: 500;
}

.login {
  padding-left: 60px;
}
.ladoDireito .login p {
  color: #62636c;
  font-size: 0.9em;
  margin-top: 5px;
}

.ladoDireito .login label p {
  font-size: 0.85em;
  color: black;
  font-weight: 400;
}
.senhaLabel {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.ladoDireito .login label .forgot {
  font-size: 0.7em;
  color: #8b8d98;
  text-decoration: underline;
}
.ladoDireito .login label .forgot:hover {
  cursor: pointer;
}
.loginInputs input {
  margin-top: -5px;
  padding: 10px 35px;
  background-color: #fafafa;
  border: 1px solid #cdced7;
  font-size: 0.7em;
  width: 100%;
}

.ladoDireito .login .errorGeral{
  margin-top: 0px;
}

.direitos {
  text-align: right;
}
.ladoDireito .login .error {
  color: red;
  font-size: 0.8em;
}
.ladoDireito .entrar {
  margin-top: 40px !important;
  width: 150px;
  height: 35px;
  border-radius: 6px;
  background-color: #74f325;
  border: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-size: 0.9em;
  font-weight: 500;
  transition: 0.5s;
}
.ladoDireito .entrar:hover {
  background-color: #3bae12;
  transition: 0.5s;
}

.ladoDireito .entrar:disabled{
  opacity: 0.8;
}

.ladoDireito .entrar:disabled:hover{
  background-color: #74f325;
}

.ladoDireito .loadingtrue{
  padding: 6px 68px !important;
}

.ladoDireito .emailEnviado{
  width: 100%;
  max-width: 500px;
  color: #62636c;
  font-size: 0.8em !important;

}

.backHome {
  display: block;
  border: 1px solid black;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 4px 48px;
  border-radius: 6px;
  margin-top: 80px !important;
  display: flex;
  position: relative;
}
.loginInputs .eyeIcon {
  position: absolute;
  left: 93%;
  width: 18px !important;
  height: 18px !important;
  top: 14%;
}

.loginInputs .eyeIcon:hover {
  cursor: pointer;
  
}

.awaitIcon{
  width: 20px;
  -webkit-animation: awaitIcon 1s infinite linear;
          animation: awaitIcon 1s infinite linear;
}



@-webkit-keyframes awaitIcon {
  0%{
    transform: rotateZ(0deg);
  }
  100%{
    transform: rotateZ(360deg);
  }
}



@keyframes awaitIcon {
  0%{
    transform: rotateZ(0deg);
  }
  100%{
    transform: rotateZ(360deg);
  }
}



.login .enabletrue {
  display: none;
}

.backHome:hover {
  text-decoration: none;
}

.backHome button {
  background-color: transparent;
  border: none;
  outline: 0;
  font-size: 0.9em;
  font-weight: 500;
}

.backHome img {
  position: absolute;
  left: 0;
  padding: 5px 12px;
  width: 25%;
}

.inputContainer {
  position: relative;
  width: 100%;
}
.loginInputs img {
  position: absolute;
  width: 20px !important;
  height: 20px;
  left: 10px;
  top: 10%;
}
.logoMerxLadoDireito {
  display: none;
}

@media screen and (min-width: 1120px) and (max-width: 1310px) {
  .ladoEsquerdo h1 {
    font-size: 2.2em;
  }
  .ladoEsquerdo p:nth-child(2) {
    font-size: 1em;
  }
  .ladoEsquerdo p:nth-child(3) {
    font-size: 0.8em;
    margin-top: 20px;
  }
  .ladoEsquerdo {
    padding: 30px;
  }
}
@media screen and (max-width: 1119px) and (min-width: 777px) {
  .ladoEsquerdo h1 {
    font-size: 1.8em;
    width: 100%;
  }
  .ladoEsquerdo p:nth-child(2) {
    font-size: 0.8em;
    line-height: 20px;
    width: 100%;
  }
  .ladoEsquerdo p:nth-child(3) {
    font-size: 0.7em;
    margin-top: 20px;
  }
}

@media screen and (max-width: 776px) {
  .mainComponent {
    flex-direction: column;

    background-image: url(/static/media/backgroundLadoEsquerdo.0b32ef70.svg);
    background-size: cover;
  }
  .logoMerxLadoDireito {
    display: block;
  }
  .ladoDireito {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
  .ladoEsquerdo {
    display: none;
  }
  .ladoDireito .emailEnviado{
    padding: 0px 10px;
  }
  .ladoDireito {
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    color: white;
    padding: 20px 20px;
  }

  .login {
    padding: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ladoDireito .loginInputs {
    width: 90%;
  }
  .ladoDireito .login label p,
  .solicitarLogin p,
  .solicitarLogin p {
    color: white;
  }
  .direitos {
    margin-top: 20px !important;
    order: 2;
  }
  .solicitarLogin {
    order: 1;
    flex-direction: column;
    margin-top: -20px;
  }
  .solicitarLogin button {
    color: white;
    border: 1px solid white;
    grid-gap: 2px;
    gap: 2px;
  }
  .solicitarLogin {
    margin-top: 10px;
    grid-gap: 10px;
    gap: 10px;
  }
  .backHome {
    background-color: white;
  }
}
@media screen and (max-width: 430px) {
  .ladoDireito h3 {
    font-size: 1.2em;
    font-weight: bold;
  }
}

.cvt-title{
    margin-top: 50px;
    font-size: 38px;
    letter-spacing: 3px;
    font-weight: bold;
}

.cvt{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    
}

.cvt-item{
    font-size: 22px;
    font-weight: 500;
}

.active-item{
    text-decoration: underline;
}

.headerButtons{
    display: flex;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;


}

.masterUniqueButton{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #E5E5E5;
}

.cadMarket{
    background-color: #0067CC;
    padding: 5px 15px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;

    margin-left: 50%;
    transform: translate(-50%);
}

.uniqueButton{
    width: 393px;
    border-bottom: 2px solid #646464;
    color: #646464;
    margin: 30px 0px 20px 0px;

    font-family: Encode Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    /* or 225% */

    text-align: center;
    letter-spacing: 0.1em;
    cursor: pointer;

}

.master-containerMarket{
    justify-content: center;
    background-color: #E5E5E5;
}

.activeMarketButton{
    color: #0067CC;
    border-bottom: 2px solid #0067CC;;
}

.titleMarketPlace{
    font-family: Encode Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 52px;
    /* or 186% */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #646464;

    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    /*margin-left: 245px;
    
    */
    
    width: 1200px;
    margin-bottom: 5px;
}

.cardsLineMarket{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.CarouselCardMarket{
    
    padding-top: 5px;
    
    width: 1200px;
    text-align: center;
    
    margin-left: 50%;
    transform: translateX(-50%);

    margin-bottom: 15px;
    
}

.cardMarket{
    width: 280px;
    height: 182px;
    background-color: white;
    margin: 0px 10px;
    border-left: 3px solid #0067CC;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 16px 27px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all linear 0.3s;

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
    
}

.cardMarket:hover{
    box-shadow: 0px 4px 12px -1px rgba(0,0,0,0.75);
    transform: translateY(-3px);
}

.titleCardMarket{
    font-weight: 600;
    font-size: 12px;
    /* or 467% */

    display: flex;
    align-items: center;
    letter-spacing: 0.1em;

}

.CardMarketContent{
    font-size: 12px;
    letter-spacing: 0.1em;

    display: flex;
    flex-direction: row;
    
    align-items: stretch;
    text-align: center;

}

.iconMarket{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.paginationContainer{
    display: flex;
    flex-direction: row;
    
    justify-content: center;
    
}

.paginationContent{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0px;
    margin: 0px;
    line-height: 20px;
    width: 200px;

    

    margin-left: 100%;
    transform: translateX(-100%);

}

.paginationText{
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    letter-spacing: 0.3px;

    color: #000000;
}


.paginationButton{
    cursor: pointer;
}

.hideCardMarket{
    display: none;
}

@media only screen and (max-width: 800px){
    .masterUniqueButton{
        display: none;
    }

    .titleMarketPlace{
        margin-left: 30px;
        margin-top: 50px;
        margin-bottom: 40px;
    }

    .cardMarket{
        margin: 5px 10px;
        
    }
}


.trilha {
  margin: 60px 0 0px;
}

.trilha p,
.trilha h2,
.trilha h3 {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}

.videos ul {
  list-style: none;
  display: flex;
  margin-top: 20px;
  max-width: 80%;
  grid-gap: 10px;
  gap: 10px;
  padding: 0;
  overflow-x: auto;
  scrollbar-width: none;
  position: relative;
  align-items: flex-end;
}

.videos {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videos li {
  margin-top: 20px;
  min-width: 190px;
  padding-bottom: 10px;
}
.videos li:nth-child(1) {
  margin-left: 5px;
}


.videos li:nth-child(1) {
  margin-left: 5px;
}

.videos ul li h3 {
  font-size: 1.3em;
  margin-left: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.videos button {
  border: 0;
  background-color: transparent;
}
.buttonsMobileTrilha {
  display: none;
}

@media (min-width: 1280px) {
  .videos ul li iframe {
    width: 190px; /* Limita a largura máxima para habilitar a rolagem */
  }
}

@media screen and (max-width: 768px) {
  .videos button {
    display: none;
  }
  .buttonsMobileTrilha {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
  }
  .videos {
    justify-content: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .videos button {
    display: none;
  }
  .buttonsMobileTrilha {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
  }
  .videos {
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 420px) {
  .videos ul li iframe {
    min-width: 150px;
    max-width: 150px;
  }

  .videos ul {
    align-items: center;
    scroll-padding-left: 40px;
    grid-gap: 10px;
    gap: 10px;
  }
  .ytp-title-link,
  .yt-uix-sessionlink {
    color: none !important;
  }
}

.videoUI {
  width: 185px;
  height: 284px;
  background-color: #011101;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in;
}

.infosText {
  max-width: 150px;
}

.videoUI:hover {
  transform: scale(1.02);
  transition: 0.3s ease-in;
  background-color: #102700;
}

.videoUI h3 {
  color: #eefed3;
  font-size: 0.9em !important;
  margin: 0 !important;
  padding: 10px 15px;
}

.videoUI:hover {
  text-decoration: none;
}

.videoUI h2 {
  font-size: 1.15em;
  color: #eefed3;
  margin-top: 10px;
  margin: 0px 15px;
  font-weight: bold;
  max-width: 153px;
  min-width: calc(1.5em * 2); /* Altura mínima de 3 linhas */
}
.videoUI p {
  margin: 0;
}
.iconImageDownload {
  width: 17px !important;
}
.iconAula {
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}
.arquivo {
  display: flex;
  justify-content: flex-start;
  width: auto !important;
  align-items: center;
  padding: 10px;
  font-size: 0.9em;
  color: #bbfb7b !important;
}
.arquivo p {
  text-decoration: underline;
}

.falseEnableAula {
  color: #167407 !important;
}
.aulaCCEE {
  text-align: center;
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.4);
  padding-bottom: 4px;
}

div.container {
  max-width: 1280px;
  margin: 0 auto;
}

.container,
.send-btn-new {
  font-family: "Poppins", sans-serif;
}
.content h2 {
  margin: 60px 0 30px;
  font-weight: 500;
  font-size: 1.5em;
  font-family: "Poppins", sans-serif;
}

#btnSearch:focus {
  outline: black;
  background-color: white;
  color: black;
}
#btnEconomy:focus {
  background-color: #7fe93c;
  outline: none;
  color: black;
}

.container .buttonsLink .btnSimulationEconomy {
  background-color: #74f325;
  color: black;
  border: 2px solid #a0f75a;
  font-weight: 500;
  outline: none !important;
  border-radius: 6px;
}

.container .buttonsLink button {
  outline: none !important;
}
.container .buttonsLink:focus {
  outline: none !important;
}
.container .btnSimulationEconomy:hover {
  background-color: #7fe93c;
  outline: none !important;
  color: black;
}
.container .buttonsLink .btnResearchFreeMarket {
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 6px;
}

.container .buttonsLink {
  display: flex;
  grid-gap: 10px 2%;
  gap: 10px 2%;
  flex-wrap: wrap;
}
.buttonsLink a {
  width: 49%;
  min-width: 200px;
  font-weight: 500;
  display: flex;
}
.buttonsLink a button {
  width: 100%;
  padding: 6px 12px;
}

.container-Parceiro .line {
  background-color: rgba(224, 225, 230, 0.3);
  border: none;
}

.clientesIndicados {
  background-color: rgba(217, 217, 217, 0.3);
  border: 2px solid #cdced7;
  padding: 20px;
  width: 49%;
  border-radius: 6px;
  min-width: 350px;
}

@media screen and (min-width: 1050px) {
  .infoClientesCompleta {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
  }
}

@media screen and (max-width: 529px) and (min-width: 468px) {
  .buttonsLink a .btnResearchFreeMarket {
    padding: 6px 20px;
  }
}

.container-Parceiro {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.buttonsLink {
  margin-top: 20px;
}

@media screen and (max-width: 776px) {
}

@media screen and (max-width: 695px) {
  .buttonsLink Button {
    font-weight: 600 !important;
  }
}
@media screen and (max-width: 636px) {
  .clientesIndicados {
    min-width: 50%;
  }
}

@media screen and (max-width: 321px) {
  .buttonsLink a button {
    font-size: 0.77em;
  }
}

.clientesIndicacao,
.docs {
  display: flex;
  grid-gap: 20px 2%;
  gap: 20px 2%;
  flex-wrap: wrap;
}

.clientesIndicados .error {
  color: rgb(215, 18, 18);
  font-size: 0.8em;
}

.sendFile {
  width: 23px;
  margin: 0 3px;
}

.deleteFile {
  width: 10px;
  right: 0;
  margin-right: 10px;
  top: 40%;
  position: absolute;
}

.documentosEnviados .send-btn-new {
  background-color: white;
  border: 2px solid #cdced7;
  padding: 12px 70px 12px 10px;
  font-weight: 500;
  border-radius: 6px;
  font-size: 1.1em;
  color: black;
  text-wrap: nowrap;
  position: relative;
}
.fileSelectedfalse {
  width: 100%;
}

.documentosEnviados .send-btn-newtrue,
.fileSelectedtrue {
  width: 100%;
}

.documentosEnviados .send-btn-button-new {
  color: black;
  font-weight: 600;
  background-color: #74f325;
  border: 2px solid #55d01b;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 0.9em;
}
.inputsInvoices {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.inputInvoice {
  width: 48%;
  min-width: 150px;
}
.inputInvoice input {
  width: 100%;
  border-radius: 5px;
  outline: 0;
  border: 1px solid #cdced7;
  padding: 5px 10px;
}
.inputInvoice h3 {
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.5);
}
.docs .documentosEnviados p:nth-child(2) {
  margin: 10px 0;
}

.btnsSendfalse {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  justify-content: space-between;
}

.btnsSendtrue {
  display: flex;
  flex-direction: column;
}

.send-btn-new:hover {
  cursor: pointer;
}
.documentosEnviadosParaMerx {
  margin-bottom: 20px;
}

@media screen and (max-width: 774px) {
  .clientesIndicados,
  .documentosRecebidos {
    width: 100%;
  }
}

@media screen and (min-width: 774px) and (max-width: 960px) {
  .documentosRecebidos .pdf-info {
    max-width: 230px;
  }
}

@media screen and (max-width: 500px) {
  .documentosRecebidos .pdf-info {
    max-width: 60%;
  }
  .clientesIndicadosEconomy p {
    font-size: 2em;
  }
}

.clientesIndicados h2,
.clientesIndicados p,
.documentosRecebidos h2,
.documentosRecebidos p,
.documentosRecebidos span {
  font-family: "Poppins", sans-serif;
}

.clientesIndicados h2,
.documentosRecebidos h2 {
  font-size: 1.5em;
}

.envieDoc {
  margin-bottom: 20px !important;
}

.clientesIndicados p,
.documentosRecebidos p {
  color: #8b8d98;
  font-size: 0.9em;
}

.documentosRecebidos p {
  margin-top: 70px;
  color: #8b8d98;
}

.clientesIndicadosEconomy {
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 29%;
  min-width: inherit;
}

.clientesIndicadosEconomy p {
  margin-bottom: 0px;
  width: 100%;
}

.valueEconomy {
  color: #55d01b !important;
  font-size: 2.2em !important;
  font-weight: bold;
  word-wrap: break-word; /* Permite quebra de linha */
}

.especialistasButton button {
  width: 100%;
  background-color: #74f325 !important;
  outline: 0;
  border: 2px solid #55d01b;
  color: black;
  font-weight: 500;
}
.especialistasButton button:hover,
.especialistasButton button:focus {
  border: 2px solid #55d01b !important;
  color: black !important;
  outline: none !important;
}

.texts {
  display: flex;
  flex-direction: column;
}

.texts .unidades {
  margin-top: auto;
  margin-bottom: 0;
  color: #55d01b;
  font-size: 1.4em;
  font-weight: 500;
}

.direitos {
  margin: 10px auto;
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
  margin-bottom: 10px;
  font-weight: 300;
}

.documentosRecebidos {
  padding: 20px 20px;
}

.documentosRecebidos h2 {
  margin: 0;
}
.documentosRecebidos p {
  width: 100%;
  margin: 10px 0 20px;
}
.documentosRecebidos .mb-pdf {
  margin-bottom: 10px;
}
.pdf-icon {
  margin-top: 4px;
}

.pdf-name-new {
  color: #3bae12;
  font-weight: bold;
  text-decoration: underline;
}
.pdf-size {
  color: black;
  font-weight: 500;
}
.mb-pdf {
  color: #8b8d98;
}

.container-2 {
  max-width: 100vw;
  margin: 0 auto;
  margin-top: 80px;
  background-color: #f5f5f5;
}

.partner-area {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.partner-info-company {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
}

.partner-info-company h1 {
  font-size: 42px;
  font-weight: 300;
}

.data-info {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  justify-content: space-evenly;
}

.data-info h2 {
  font-size: 16px;
}

.bold-text {
  font-weight: 400;
  pointer-events: none;
}

.title-partner {
  margin-bottom: 27px;
}

.gray-text {
  color: #7e7e82;
  font-size: 1rem;
}

.cnpj-info {
  min-width: 150px;
}

.cnpj-info:last-child {
  margin-bottom: 18px;
}
*/ .btn-edit {
  width: 53px;
  height: 19px;
  font-size: 16px;
  color: #0072ce;
  outline: 0;
  border: 0;
  cursor: pointer;
}

.logo img {
  position: relative;
  width: 180px;
  height: auto;
}

.economy-area {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 24px;
}

h1,
h2,
h3,
h4,
p,
span {
  font-family: "Roboto", sans-serif;
}

.no-content {
  font-weight: 400;
  font-size: 20px;
}

.value-card {
  background-color: #f5f5f5;
  /* width: 500px; */
  min-height: 100px;
  padding-right: 30px;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 10px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; */

  background-color: #f5f5f5;
}

.value-card h2 {
  font-size: 23px;
  font-weight: 300;
}

.value-card p {
  font-weight: 700;
  font-size: 22px;
}

.total-economy {
  color: #7e7e82;
  font-size: 16px;
}

.docs-area {
  margin-top: 68px;
  display: flex;
  justify-content: space-between;
}

.border-docs {
  border-left: 2px solid black;
}

.docs-text-area h2 {
  font-weight: 300;
  font-size: 32px;
}

.docs-text-area p {
  font-weight: 400px;
  font-size: 16px;
  color: #7e7e82;
}

.mb {
  margin-bottom: 37px;
}

.send-btn {
  display: inline-block;
  position: relative;
  padding: 17px 35px;
  border: 1px solid #0072ce;
  color: #0072ce;
  transition: 0.2s;
  cursor: pointer;
}

.send-btn:hover {
  background: #fafafa;
}

.send-btn-button {
  display: inline-block;
  position: relative;
  background: #0072ce;
  padding: 17px 35px;
  border: 1px solid #0072ce;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
}

.button-loading {
  position: relative;
  top: 5px;
  left: 5px;
  background: transparent;
  border: 0;
}

.send-btn-button:disabled {
  background: #2089de;
  cursor: not-allowed;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.pdf-area {
  display: flex;
  flex-direction: column;
  height: 200px;
  max-width: 420px;
  overflow-y: auto;
}

.pdf-icon {
  width: 35px;
  height: 29px;
}

.pdf-material {
  display: flex;
  padding-top: 10px;
  width: 100%;
  margin-top: 5px;
}

.pdf-info {
  display: flex;
  cursor: pointer;
  flex-direction: column !important;
  position: relative;
  top: -5px;
}

.pdf-image {
  margin-right: 5px;
}

.pdf-info span,
.pdf-info p {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.pdf-area .pdf-info .pdf-name {
  font-size: 12px;
}

.pdf-area .pdf-info .pdf-size {
  font-size: 12px;
}

.size-color {
  color: #7e7e82;
}

.mb-pdf {
  margin-bottom: 24px;
}

.pdf-area::-webkit-scrollbar {
  width: 10px; /* Largura da scrollbar */
  border-radius: 0;
}

.pdf-area::-webkit-scrollbar-track {
  background: #fff; /* Cor de fundo do track */
  border-radius: 0;
  box-shadow: none;
}

.pdf-area::-webkit-scrollbar-thumb {
  background-color: #f5f5f5; /* Cor do thumb */
  border-radius: 0;
}

.divisor-bar {
  height: 350px;
  width: 1px;
  background: #7e7e82;
}

.parceiro-iframe {
  width: 100%;
  max-width: 350px;
  height: 300px;
}

.videos-container {
  /* background: #f5f5f5; */
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videos-container h1 {
  font-style: normal;
  width: 100%;
  text-align: start;
  font-size: 32px;
  font-weight: 300;
  color: #212322;
}

.videos-container h2 {
  font-style: normal;
  width: 100%;
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  color: #7e7e82;
}

.videos-container h3 {
  font-style: normal;
  width: 100%;
  text-align: end;
  font-size: 12px;
  font-weight: 400;
  color: #7e7e82;
}

.videos-container-section {
  width: 100%;
  max-width: 350px;
}

.videos-grid {
  width: 100%;
  position: relative;
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(auto-fill, minmax(339px, 1fr));
  justify-items: center;
  grid-gap: 21px;
  gap: 21px;
  grid-row-gap: 21px;
}

.material-grid {
  width: 100%;
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(auto-fill, minmax(339px, 1fr));
  justify-items: center;
  justify-content: center;
  align-items: center;
  grid-gap: 21px;
  gap: 21px;
  grid-row-gap: 21px;
  margin-top: 32px;
}

.infoClientesCompleta {
  width: 69%;
  min-width: inherit;
}

.pdf-card {
  background-color: #ffffff;
  padding: 20px 20px;
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: 98px;
  text-decoration: none;
  grid-gap: 10px;
  gap: 10px;
}

.pdf-card-text {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.title-card {
  color: #212322;
}

.description-card {
  color: #7e7e82;
}

.pdf-info {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.pdf-info img {
  margin-right: 10px;
}

.pdf-link {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  color: #0072ce;
}
.falseBlack {
  opacity: 50%;
}
.pdf-material-sended {
  margin-top: 5px;
  padding-bottom: 0;
}
.pdf-material-sended .mb-pdf {
  padding: 0;
  margin: 0;
}

.documentosEnviadosPelaMerx {
  margin-top: auto;
}

@media (max-width: 1200px) {
  .data-info {
    flex-direction: column;
  }
  .economy-area {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .partner-area {
    flex-direction: column-reverse;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
  }

  .value-card {
    margin-bottom: 30px;
  }

  .cadastros-container-section-profile {
    flex-direction: column;
  }

  /* .box-center {
    width: 100%;
    display: flex;
    justify-content: center;
  } */

  .total-economy {
    text-align: center;
    box-sizing: border-box;
    /* padding: 0; */
  }

  .docs-area {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(0.875);
  }

  .divisor-bar {
    display: none;
  }

  .pdf-area {
    margin-bottom: 30px;
  }

  .videos-container h1,
  .videos-container h2 {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 901px) {
  .container {
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .partner-area {
    justify-content: center;
  }

  .valueEconomy {
    font-size: 1.61em !important;
  }

  .clientesIndicacao {
    flex-direction: column;
  }

  .infoClientesCompleta,
  .clientesIndicadosEconomy {
    width: 100%;
  }

  .value-card {
    width: 300px;
  }

  .value-card h2 {
    /* padding-right: 0; */
    font-size: 14px;
  }

  .value-card p {
    text-align: left;
    font-size: 18px;
  }

  .docs-text-area h2 {
    font-size: 24px;
  }

  .send-btn,
  .send-btn-button {
    padding: 10px;
  }
}

@media (max-width: 400px) {
  .videos-container-section {
    width: 80%;
  }

  .parceiro-iframe {
    height: 200px;
  }
}

.cursos-section {
  background: #f5f5f5;
}

.cpf-info {
  font-weight: bold;
  font-size: 10px;
}

@media (max-width: 700px) {
  .value-card p {
    margin-left: 0;
  }
}
@media screen and (max-width: 500px) {
  .buttonsLink a {
    width: 100%;
  }

  .clientesIndicados h2,
  .documentosRecebidos h2 {
    font-size: 1.3em;
  }
  .btnsSendfalse,
  .btnsSendtrue {
    flex-direction: column !important;
    grid-gap: 10px;
    gap: 10px;
  }
  .btnsSendfalse span,
  .btnsSendtrue span {
    width: 100%;
  }
  .documentosEnviados .btnsSend span,
  .documentosEnviados .btnsSend button {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .documentosEnviados .btnsSend span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  .documentosEnviados p {
    margin-top: 0;
  }
  .clientesIndicados p,
  .documentosRecebidos p {
    width: 100%;
    margin-left: 0;
  }
  .pdf-material {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
  }
  .pdf-material .pdf-info {
    width: 100%;
    max-width: 100%;
  }
  .documentosRecebidos .mb-pdf {
    margin-bottom: 0;
  }
  .button-area {
    margin-top: -15px;
    padding-bottom: 5px;
  }
}

@media only screen and (max-width: 700px) {
  p {
    margin-left: 0px;
  }
}

.saveInfos {
  background-color: #a0f75a !important;
  border: 1px solid #74f325 !important;
  transition: 0.7s !important;
  color: black !important;
}
.saveInfos:hover {
  background-color: #74f325 !important;
  transition: 0.7s !important;
}
.modal-footer {
  border-top: none !important;
  justify-content: space-between !important;
  padding: 0 !important;
 
}

.modal-footer:nth-child(2){
  margin-bottom: 30px !important;
}


.trueEnableError {
  display: block !important;
  opacity: 1;
}
.falseEnableError{
  opacity: 0;
}
.infosFooterPartner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.valid-feedback,
.invalid-feedback {
  max-width: 300px;
}

@media screen and (max-width: 430px) {
  .infosFooterParter {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
  }
  .saveInfos {
    width: 210px !important;
  }
}

.parterInfosContainer {
  margin: 30px 0px 10px;
  padding: 20px;
  border: 1px solid;
  background-color: rgba(217, 217, 217, 0.3);
  border: 1px solid #cdced7;
  display: flex;
  width: 100%;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  align-content: center;
  position: relative;
  align-items: center;
  border-radius: 6px;
}

.parterInfosContainer p,
.parterInfosContainer h2,
.parterInfosContainer h3,
.parterInfosContainer h4 {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.parterInfosContainer .iconChange {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  border: none !important;
  background-color: transparent;
}

.parterInfosContainer .logoImage {
  margin: auto;
  width: 100%;
  min-width: 100px;
  padding: 5px;
}

.parterInfosContainer h2 {
  font-size: 1.8em;
  font-weight: 600;
}
.parterInfosContainer h3 {
  font-size: 1.1em;
  font-weight: 600;
  align-self: flex-start;
}
.infosCliente {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 3px;
  gap: 3px;
}
.parterInfosContainer h4 {
  font-size: 1.1em;
  margin-top: 5px;
  font-weight: 600;
}

.calendar {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  align-self: flex-start;
}
.calendar img {
  width: 20px;
  height: 100%;
}

.calendar p {
  margin-top: 1px;
}
.parterImage {
  border: 2px solid #74f325;
  border-radius: 100%;

  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.skeleton-loader {
  width: 100%;
  max-width: 150px;
  max-height: 150px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  -webkit-animation: skeleton-loading 1.5s infinite;
          animation: skeleton-loading 1.5s infinite;
  background-size: 200% 100%;
  border: 2px solid #74f325;
  border-radius: 100%;
}

.nameCompany {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1000px;
}

@-webkit-keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.iconChange:hover {
  cursor: pointer;
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .parterInfosContainer {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
  .parterInfosContainer h2 {
    font-size: 1.4em;
  }
  .parterInfosContainer h3 {
    font-size: 1em;
  }
  .parterInfosContainer h4 {
    font-size: 1em;
  }
  .calendar p,
  .clientesNumber {
    font-size: 0.9em;
  }
  .infosCliente {
    justify-content: center;
  }
}
@media screen and (min-width: 540px) and (max-width: 599px) {
  .parterInfosContainer {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
  .parterInfosContainer h2 {
    font-size: 1.3em;
  }
  .parterInfosContainer h3 {
    font-size: 0.9em;
  }
  .parterInfosContainer h4 {
    font-size: 0.9em;
  }
  .calendar p,
  .clientesNumber {
    font-size: 0.85em;
  }
}
@media screen and (min-width: 530px) and (max-width: 549px) {
  .parterInfosContainer {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
  .parterInfosContainer h2 {
    font-size: 1.2em;
  }
  .parterInfosContainer h3 {
    font-size: 0.8em;
  }
  .parterInfosContainer h4 {
    font-size: 0.8em;
  }
  .calendar p,
  .clientesNumber {
    font-size: 0.75em;
  }
  .clientesNumber p {
  }
  .calendar {
  }
  .parterInfosContainer {
    grid-row-gap: 5px;
    row-gap: 5px;
  }
}

@media screen and (min-width: 475px) and (max-width: 529px) {
  .parterInfosContainer {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
  .parterInfosContainer h2 {
    font-size: 1.2em;
  }
  .parterInfosContainer h3 {
    font-size: 0.9em;
  }
  .parterInfosContainer h4 {
    font-size: 0.9em;
  }
  .calendar p,
  .clientesNumber {
    font-size: 0.75em;
  }
  .clientesNumber p {
  }
  .calendar {
  }
  .parterInfosContainer {
    grid-row-gap: 5px;
    row-gap: 5px;
  }
}

@media screen and (min-width: 378px) and (max-width: 474px) {
  .parterImage {
    align-self: center;
    min-width: 100px;
  }
  .parterInfosContainer {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
  .parterInfosContainer h2 {
    font-size: 1.1em;
    width: 100%;
  }
  .parterInfosContainer h3 {
    font-size: 0.8em;
  }
  .parterInfosContainer h4 {
    font-size: 0.8em;
  }
  .calendar p,
  .clientesNumber {
    font-size: 0.7em;
  }
  .parterInfosContainer .clientesNumber {
  }

  .parterInfosContainer {
    padding: 5px;
  }

  .calendar {
  }
}

@media screen and (max-width: 376px) {
  .parterImage {
    align-self: center;
    min-width: 100px;
  }
  .parterInfosContainer {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
  .parterInfosContainer h2 {
    font-size: 1em;
    text-wrap: wrap;
    max-width: 150px;
  }
  .parterInfosContainer h3 {
    font-size: 0.7em;

    text-wrap: wrap;
  }
  .parterInfosContainer h4 {
    font-size: 0.7em;

    max-width: 150px;
  }
  .calendar p,
  .clientesNumber {
    font-size: 0.6em;
  }
  .parterInfosContainer .clientesNumber {
    margin-top: 0px !important;
  }
  .calendar {
    max-width: 150px;
  }
  .parterInfosContainer {
    grid-row-gap: 5px;
    row-gap: 5px;
    padding: 5px;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
  }
}

@media screen and (max-width: 776px) {
  .parterInfosContainer {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    width: 80%;
    margin: 30px auto;
  }
  .infosCliente {
    max-width: 100%;
  }
  .parterInfosContainer h2 {
    max-width: 100%;
  }
}

.allContent {
  width: 100%;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: center;
}
.allContent .buttonNext,
.allContent .buttonBack {
  border: none;
  background-color: unset;
}

.indicado {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  order: 2;
  padding: 0px 10px;
  height: 150px;
}
.indicados {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: 5px 0px;
  width: 350px;
  min-width: 400px;
  flex-wrap: nowrap; /* Evita quebra de linha */
  scrollbar-width: none;
}

.indicados .migracaoDate {
  color: #cdced7;
}

.indicado h3,
.indicado p,
.indicado h2 {
  margin: 0;
  font-size: 0.79em;
  font-family: "Poppins", sans-serif;
}

.migrationStatusCliente {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
}

.migrationStatusCliente h3,
.cnpj {
  font-weight: 400;
  color: #62636c;
}

.infosIndicado {
  display: flex;
  background-color: #fff;
  border: 1px solid #cdced7;
  border-radius: 6px;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  width: 100%; /* Faz o item ocupar 100% da largura */
  min-width: 100%;
  grid-gap: 15px;
  gap: 15px;
  flex-direction: column;
}

.infosIndicado .empresaLogo {
  width: 80%;
  max-height: 80px;
  aspect-ratio: 1 / 1; /* Proporção de 1:1 para manter a largura proporcional à altura */
  object-fit: contain; /* Ajusta o conteúdo para caber no elemento mantendo a proporção */
  padding-top: 10px;
}

.acessClient {
  font-size: 0.8em;
  background-color: #74f325;
  border: 1px solid #a0f75a;
  color: #1e1f24;
  border-radius: 6px;
  padding: 5px;
  font-weight: 500;

  justify-self: end;
}

.trueEnable {
  visibility: hidden;
}

.acessClient:hover {
  background-color: #74f325;
  transition: 0.7s;
  color: black;
}

.indicado .falseCheck {
  margin-top: 29px !important;
}
.buttonsMobile {
  display: none;
}

.point {
  font-size: 1em;
}

@media screen and (max-width: 1050px) {
  .allContent {
    justify-content: center;
    align-items: center;
  }

  .indicado .falseCheck {
    margin-top: 28px !important;
  }
}

@media screen and (max-width: 491px) {
  .infosIndicado {
    flex-direction: column;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    min-width: 100%;
  }
  .indicado {
    order: 2;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .clientesIndicados .indicados p {
    width: 95% !important;
  }
}

@media screen and (max-width: 545px) {
  .f-w {
    display: none;
  }
  .indicados {
    width: 200px;
    min-width: 200px;
  }

  .allContent {
    flex-direction: column;
  }
  .buttonsMobile {
    display: flex !important;
    width: 200px;
    grid-gap: 11px;
    gap: 11px;
    flex-wrap: wrap;
  }
  .indicado {
    height: 180px;
  }
}

.migrationStatus {
    background-color: rgba(217, 217, 217, 0.3);
    border: 2px solid #cdced7;
    padding: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
  
  .migrationStatus h2,
  .migrationStatus p,
  .migrationStatus select,
  .migrationStatus option,
  .migrationSteps h3,
  .migrationSteps p,
  .migrationStepsh h4 {
    font-family: "Poppins", sans-serif;
  }
  
  .migrationStatus h2 {
    font-size: 1.5em;
  }
  .migrationStatus select {
    width: 100%;
    padding: 10px;
    border: 2px solid #cdced7;
    border-radius: 6px;
    color: #80828d;
  }
  .migrationStatus option {
    padding: 0 10px;
  }
  .migrationSteps {
    display: flex;
    align-items: top;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    width: 12%;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
  }
  
  
  
  .step h3 {
    margin-top: 5px;
    font-size: 1.3em;
  }
  .step img:nth-child(1) {
    width: 52px;
  }
  .step img:nth-child(2) {
    width: 10px;
    margin-top: 15px;
  }
  
  .stepNumber {
    color: #80828d;
    border: 1px solid #80828d;
    padding: 10px 18px;
    border-radius: 100%;
  }
  .step p,
  .step h4 {
    margin-bottom: 0;
  }
  .step p {
    color: #80828d;
    font-weight: 500;
    margin-top: -5px;
    text-align: center;
  }
  .step .waiting {
    color: #8b8d98;
    font-weight: 300;
  }
  
  @media screen and (max-width: 900px) {
    .step {
      width: 30%;
    }
    .migrationSteps {
      grid-gap: 40px 20px;
      gap: 40px 20px;
    }
    .step p {
      margin-left: 0;
    }
  }
  
  @media screen and (max-width: 600px) {
    .step {
      width: 45%;
      align-items: center;
      text-align: center;
    }
    .migrationSteps {
      grid-gap: 40px 20px;
      gap: 40px 20px;
    }
    .step p {
      font-size: 0.8em;
    }
  }
  
  @media screen and (max-width: 360px) {
    .migrationStatus h3 {
      font-size: 1em;
    }
    .migrationStatus p{
      font: 0.8em;
    }
  }

  @media screen and (max-width:303px) {
    .migrationSteps{
      justify-content: center;
    }
  }
.container-data-master{
    font-family: 'Encode Sans', sans-serif;
    position: relative;
}
.container-data-external{
    text-align: center;
    
}
.container-data{
    display: flex;
    flex-direction: column;
    background-color: rgb(194, 223, 253);
    padding-bottom: 10px;
    color: white;

}

.linha-data{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 500px;
    background-color: #0067CC;
    border-radius: 4px;
    padding: 0px 50px;
    margin-top: 10px;
}

.title-data{
    font-size: 22px;
    font-weight: bold;
}

.item-data{
    font-size: 18px;
    margin-left: 5px;
}

@media only screen and (max-width: 600px){
    .linha-data{
        width: 100%;
    }
}
.container-register-dadosMes{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    background-color: gray;

    padding: 30px;
    border-radius: 4px;
    margin-top: 15px;
    text-align: center;

    font-family: 'Encode Sans';

}

.title-cadMes{
    font-weight: bold;
}

.register-box-dadosMes{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lineCheck{
    background-color: green;
}

.buttonsChooseDadosMes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mesCadastrado{
    display: flex;
    flex-direction: column;

    margin: 15px 0px;
    background-color: white;

    border-radius: 4px;
    padding-top: 15px;
}

.monthData{
    font-size: 14px;
    font-weight: bold;
    line-height: 15px;
}

.monthDataContent{
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    width: 50%;
}


.merCadastradoLine{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.merCadastradoLineContent{

    display: flex;
    flex-direction: row;
    justify-content: space-around;

}
.cad-files-container-external{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.cad-files-container{
    width: 1200px;
    text-align: center;
    background-color: #E5E5E5;
    margin-top: 20px;
    border-radius: 4px;
}

.logo-cad-file{
    width: 125px;
}

.inputCadFile{
    width: 400px;
}
.pageNotFound-container-master{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: relative;
}

.pageNotFound-container{
    text-align: center;
    background-color: #E5E5E5;
    position: absolute;
    top: 50%;
    transform: translateY(20%);
    padding: 40px;
    border-radius: 4px;
}

.logo-size-404{
    width: 150px;
}

.title-404{
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 1px;
}

.title-404-2{
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 2px;
}

.subtitle-404{
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    
}

.link-404{
    font-size: 18px;
    font-weight: 500;
    color: rgb(27, 27, 145);
    text-decoration: underline;
    line-height: 10px;
    cursor: pointer;
}

.Button-notFound{
    width: 170px;
    font-weight: 500;
    background-color: rgb(24, 24, 177);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 0px;
}
body {
  font-family: "Encode Sans", sans-serif;
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgb(224, 224, 224);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: #0067cc;
}



@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.consumeGraph-container{
    width: 445px;
    height: 356px;
    background-color: white;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    flex-direction: column;

}

.consume-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 0px 15px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #EBEFF2;
    font-family: Encode Sans;
}

.consume-title{
    font-weight: bold;
    font-size: 24px;
    color: #192A3E;
    font-family: Encode Sans;
}

.consume-subtitle{
    font-weight: normal;
    font-size: 18px;
    color: #192A3E;
}

.consume-content{
    position: relative;
}

.pieChart-img{
    width: 250px;
    margin-left: 50%;
    transform: translateX(-50%);

    margin-top: 10px;
}

.verticalIconChart{
    font-size: 12px;
    line-height: 0px;
}

.inside-chart-content{
    color: #0067CC;
    font-size: 32px;
    font-weight: bold;
    line-height: 10px;
}

.inside-chart-content-sub{
    color: #0067CC;
    font-size: 12px;
    font-weight: bold;

}




.inside-chart{
    position: absolute;
    text-align: center;

    left: 51.2%;
    top: 47%;
    transform: translateX(-50%);
}

.consumeGraphLegenda-1{
    position: absolute;
    top: 8%;
    left: 5%;
    font-size: 10px;
    width: 70px;
    text-align: center;
}

.consumeGraphLegenda-2{
    position: absolute;
    top: 80%;
    left: 5%;
    font-size: 10px;
    width: 70px;
    text-align: center;
}

.consumeGraphLegenda-3{
    position: absolute;
    top: 8%;
    left: 80%;
    font-size: 10px;
    width: 70px;
    text-align: center;
}


@media only screen and (max-width: 1200px){
    .consumeGraph-container{
        width: 100%;
        margin-top: 30px;
    }

}
.consumeTable-container {
  width: 100%;
  border: 1px solid #ececec;
}

.consume-content-table {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.mytable-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

.client-positivo {
  color: #1fa824 !important;
}

.client-negativo {
  color: #f91010 !important;
}

.client-regulado {
  color: #7e7e82 !important;
}

.cards-container {
  display: flex;
  flex-direction: row-reverse;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  overflow-x: auto;
  direction: rtl;
  padding-bottom: 5px;
  height: 145px;
}

.cards-container::-webkit-scrollbar {
  width: 12px;
  height: 6px;
}

.cards-container::-webkit-scrollbar-thumb {
  background-color: #888;
}

.cards-container::-webkit-scrollbar-track {
  background-color: #eaeaea;
}

.mytable-item-title {
  color: #212322;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: center;
  width: 20%;
  justify-content: flex-end;
  margin: 4px 0;
  padding: 0px 30px;
}

.mytable-item {
  color: #212322;
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  width: 20%;
  justify-content: center;
  margin: 4px 0;
  padding: 0px 30px;
  color: #7e7e82;
}

.mes-ano {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 143px;
}

@media only screen and (max-width: 1200px) {
  .consumeTable-container {
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 900px) {
  .mytable-item,
  .mytable-item-title {
    font-size: 10px;
    padding: 0;
  }
}

@media (max-width: 700px) {
  .cards-container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.consumeLine-container-master {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
  flex-direction: column;
}

.consumeLine-container-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8px 0;
}

.consumeLine-container-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 16px 0;
}

@media (max-width: 1000px) {
  .consumeLine-container-left,
  .consumeLine-container-right {
    width: 100%;
  }
}

.consumeLine-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*
      margin-left: 50%;
      transform: translateX(-50%);
      */
  flex-wrap: wrap;
}

.clientDashboard-text {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.clientDashboard-container {
  width: 100%;
  border: 1px solid #ececec;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 30px;
}

.container-grafico {
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  padding: 1em 1em 1em 1em;
  border-radius: 5px;
  display: flex;
  flex-flow: row wrap;
  width: 60%;
  height: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.container-title-grafico {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: clip;
  margin: auto;
}
.container-title-grafico select {
  margin-left: 10px;
}

.right-text {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.container-all {
  margin-top: 10px;
}

.compareBtn {
  margin: 5px 0;
}

.compare {
  display: flex;
}
.previsaoConsumoParagrafo {
  display: block;
  margin-bottom: 8px;
}
@media screen and (max-width: 700px) {
  .container-title-grafico {
    display: flex;
    flex-direction: column;
  }

  .compare {
    flex-direction: column;
    align-items: center;
  }

  .compareBtn {
    margin: 0 auto;
    width: 50%;
  }
}

@media screen and (min-width: 701px) {
  .compare {
    margin-top: 10px;
  }
  .compareBtn {
    margin-left: 5px;
  }
}

@media screen and (min-width: 715px) and (max-width: 850px) {
  .compare {
    justify-content: center;
  }
}

.right-text-center {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-text h1 {
  color: #212322;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
}

.right-text h2 {
  color: #212322;
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
}

.right-text-center p {
  color: #212322;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
}

.right-text-center h1 {
  color: #7e7e82;
  font-size: 20px;
  text-align: center;
  font-family: Roboto;
  font-weight: bold;
  padding: 6rem 0.5rem;
  margin-bottom: 0.5rem;
}

.right-text-center h3 {
  color: #212322;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  padding: 0 0.5rem;
}

.right-text-center h4 {
  color: red;
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
}

.right-text-center h5 {
  color: green;
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
}

.right-text-center h6 {
  color: black;
  font-size: 12px;
  font-family: Roboto;
  font-weight: bold;
}

.dadosConsumo {
  background-color: white;
  padding: 20px;
}
.Infos p {
  margin: 0;
}
.line {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #6c757d;
}
.Infos {
  margin-top: 20px;
  width: 80%;
  margin: 20px auto;
  display: flex;
  grid-gap: 20px 30px;
  gap: 20px 30px;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.Infos div {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Infos div p:nth-child(1) {
  font-size: 1.2em;
  color: #6c757d;
  font-weight: bold;
}
.Infos div p:nth-child(2),
.Infos div p:nth-child(3) {
  font-size: 0.9em;
  color: black;
  font-weight: 600;
}
.dadosConsumo h2 {
  font-style: normal;
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 24px;
  font-weight: 300;
  color: #6e7772;
  text-align: center;
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  .consumeLine-container {
    width: 100%;
  }
}

/* Partes dos dados measuringPoint */

.mp-info-container {
  padding: 10px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}
.mp-name-info {
  padding: 10px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}

.mp-name-info .mp-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px 100px;
  gap: 20px 100px;
  align-items: center;
}

.mp-inicio-livre-info {
  min-width: 92px;
}
.mp-info-texts {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 24px 0;
  gap: 24px 0;
  /* justify-content: space-evenly; */
}
.mp-info-text {
  display: flex;
  /* justify-content: space-evenly; */
}
.mp-info-container {
  display: flex;
  width: 100%;
}

.mp-info,
.mp-distribuidora,
.mp-code-ccee-info,
.mp-cnpj-info,
.mp-status-info,
.mp-inicio-livre-info,
.mp-inicio-gestao-info,
.mp-classe-info {
  width: 200px;
}
.mp-submercado-info {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

@media (max-width: 768px) {
  .mp-info-texts,
  .mp-info-text,
  .mp-info,
  .mp-distribuidora,
  .mp-code-ccee-info,
  .mp-inicio-livre-info,
  .mp-cnpj-info,
  .mp-status-info,
  .mp-inicio-gestao-info,
  .mp-classe-info,
  .mp-submercado-info {
    flex-direction: column;
  }
  .mp-info {
    flex-direction: column-reverse;
  }
}

.mp-info {
  display: flex;
  font-size: 1rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  grid-gap: 25px 50px;
  gap: 25px 50px;
}

@media (max-width: 740px) {
  .mp-name-info .mp-info {
    flex-direction: column;
  }
}

@media (max-width: 1040px) {
  .container-title-grafico span {
    display: block;
  }
  .container-title-grafico select {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 500px) {
  .Infos div p:nth-child(1) {
    font-size: 1em;
  }
  .Infos div p:nth-child(2),
  .Infos div p:nth-child(3) {
    font-size: 0.8em;
  }

  .Infos div p:nth-child(3) {
    font-size: 0.9em;
  }

  .Infos {
    width: 100%;
  }
  .Infos div {
    width: 100%;
  }
}

.flex-container {
  display: flex;
  align-items: center;
  grid-gap: 100px;
  gap: 100px;
}

.parterImageContainer {
  border: 2px solid #74f325;
  border-radius: 110%;

  width: 85%;
  height: 85%;
  min-width: 180px;
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.logoImage {
  max-width: 100px;
  max-height: 120px;
}

@media screen and (min-width: 378px) and (max-width: 474px) {
  .flex-container {
    flex-direction: column-reverse;
  }

  .parterImageContainer {
    width: 90%;
    height: 90%;
    min-width: 160px;
    min-height: 160px;
  }
}

.cardContent-container {
  background-color: #fff;
  border-radius: 4px;
  width: 200px;
  height: 120px;
  display: flex;
  flex-direction: column;
  border-left: solid 4px;
  transition: all 0.3s linear;
  cursor: pointer;
  margin-right: 15px;
}

.cardContent-container:hover {
  transform: translateY(-5px);
  background-color: #ebf3ed;
}

.cardContent-header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

.cardContent-header2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.cardContent-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  font-weight: 600;
  font-size: 12px;
}

.cardContent-textBox {
  width: 70%;
  margin-left: 5px;
}

.cardContent-text {
  font-size: 12px;
  padding: 0px 0px 0px 10px;
  text-align: start;
}

.icon-box-card {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

@media only screen and (max-width: 800px) {
  .cardContent-container {
    width: 100%;
    margin: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .cardContent-container {
    width: 150px;
  }

  p {
    margin-left: 10px;
  }
  p.step-description {
    margin-left: 0;
  }
}

.cardContent-container-inside {
  background-color: white;
  border-radius: 4px;
  /*
    width: 300px;
    */
  height: 80px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-left: solid 4px;
  border-radius: 0 8px 8px 0;
  margin-left: 5px;
  transition: all 0.3s linear;
  cursor: default;
  flex-wrap: nowrap;
}

.container-CoustCardItemModal{
    font-family: Encode Sans;
    background: #FFFFFF;
    border-radius: 5px;
    height: 120px;
    display: flex;
    flex-direction: column;
    border-left-style: solid;
    border-left-width: 5px;
    padding: 15px;
    transition: all .3s linear;
    color: rgb(53, 53, 53);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}


.coustCardItem-titleModal{
    font-weight: bold;
    font-size: 18px;
    /* or 187% */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #1C1C1C;
}

.coustCardItem-contentModal{
    
    display: flex;
    flex-direction: row;
    align-items: baseline;

    font-weight: 300;
    font-size: 14px;
    /* or 224% */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #1C1C1C;
}

.coutCardItem-valueModal{
    margin-left: 10px;

    font-weight: normal;
    font-size: 22px;
    /* or 140% */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    margin-top: 8px;
    color: #1C1C1C;

}

@media(max-width: 768px){

    .container-CoustCardItemModal{
        margin-bottom: 20px;
    
    }
}

.AnaliseTable-container {
  width: 98%;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* margin: 5px; */
}

.consume-title-analise {
  font-weight: bold;
  font-size: 18px;
  color: #192a3e;
}

@media only screen and (max-width: 749px) {
  .AnaliseTable-container {
    width: 100%;
  }
}

.container-modal-Info {
  width: 750px;
  text-align: center;
  background-color: white;
  padding: 10px 10px 10px 10px;
  position: relative;
}

.container-modal-info-master {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.close-icon-card {
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 100%;
  cursor: pointer;
}

@media only screen and (max-width: 749px) {
  .container-modal-Info {
    width: 100%;
  }

  .custom-dialog-info {
    transform: scale(0.875);
  }

  .custom-dialog-info::-webkit-scrollbar {
    width: 0px;
  }
}

.cardsGrid-container{
    display: flex;
    flex-direction: column;
    width: 1200px;
    flex-wrap: wrap;
}

.cardsGrid-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 20px;
    flex-wrap: wrap;
}

.container-carousel{

    display: flex;
}

.container-all {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
}
  
  /* Optional: Hide the horizontal scrollbar */
  .container-all::-webkit-scrollbar {
    display: none;
  }
.master-container-client {
  font-family: "Encode Sans", sans-serif;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
}

.clientNameInfo {
  border: 1px solid #ececec;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  min-height: 120px;
}

.logo-areaCliente img {
  height: 100px;
  width: 100px;
}

.clientNameInfo .client-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px 100px;
  gap: 20px 100px;
  align-items: center;
}
.approved-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.current-status {
  min-height: 250px;
  display: flex;
  /* min-width: 160px; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* gap: 10px; */
  position: relative;
  min-width: 131px;
  max-width: 140px;
  /* justify-content: space-evenly; */
  /* align-items: space-evenly; */
}
.migration-header {
  text-align: center;
}
.current-status p {
  display: flex;
}
.current-status .step-name,
.current-status .step-description {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 48px;
  max-height: 48px;
}

.migration-header span {
  display: block;
  word-wrap: break-word;
  text-align: center;
}

.migrations-hint {
  position: absolute;
  top: 8%;
  right: 0;
  min-height: 19px;
  min-width: 19px;
}

.bar-divider {
  border-radius: 100%;
  width: 80px;
  height: 1px;
  background-color: #d9d9d9;

  position: absolute;
  right: -60%;
  top: 50%;
}
/* .current-status span {
  margin-bottom: 1rem;
  margin-top: 1rem;
} */
.bold-text {
  font-weight: 400;
  pointer-events: none;
  font-size: 1rem;
}
.gray-text {
  color: #7e7e82;
  font-size: 1rem;
}
.client-info {
  display: flex;
  font-size: 1rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  grid-gap: 25px 50px;
  gap: 25px 50px;
}

@media (max-width: 768px) {
  .client-info {
    flex-direction: column;
  }
}

@media (max-width: 1270px) {
  .current-status {
    width: 250px;
  }
}

@media (max-width: 740px) {
  .clientNameInfo .client-info {
    flex-direction: column;
  }
}

.container-CoustCard {
  display: flex;
  justify-content: center;
}

.container-CoustCardRow {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px 0px;
  cursor: default;
  width: 1200px;
  flex-wrap: wrap;
}

.cadastros-container {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cadastros-container-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.cadastros-container-section h1 {
  font-style: normal;
  width: 100%;
  text-align: start;
  font-size: 32px;
  font-weight: 300;
  color: #212322;
}

.cadastros-container-section h2 {
  font-style: normal;
  width: 100%;
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  color: #7e7e82;
}

.cadastros-grid {
  width: 100%;
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  justify-items: center;
  justify-content: center;
  align-items: center;
  grid-gap: 41px;
  gap: 41px;
  grid-row-gap: 21px;
  margin-top: 40px;
}

.cadastros-item {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: 152px;
  text-decoration: none;
  border: 1px solid #ececec;
  grid-gap: 15px;
  gap: 15px;
}

.cadastros-item-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  grid-gap: 10px;
  gap: 10px;
}

.cadastros-item-search {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 138px;
  text-decoration: none;

  border: 1px solid #ececec;
}

@media (max-width: 600px) {
  .cadastros-item-search {
    width: 320px;
  }

  .separate {
    justify-content: center;
  }
}

.logo-affiliate {
  min-width: 75px;
  max-width: 90px;
  max-height: 90px;
  display: flex;
  flex-grow: 1;
}

.cadastros-item1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.cadastros-item2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15px;
  grid-gap: 10px;
  gap: 10px;
}

.cadastros-item3 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.cadastros-item4 {
  width: 10%;
  text-align: center;
}

.cadastros-item1 img {
  height: 100%;
  margin: auto 0;
  margin-right: 8px;
}

.cadastros-item1 h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}

.cadastros-item2 h4 {
  /* margin: 0 0 5px 0; */
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.cadastros-item2 h5 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.cadastros-item3 h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 300;
}

.cadastros-item4 span {
  color: #0072ce;
  width: 100%;
}

.CadastrosAtuvosContainer-title {
  font-family: Encode Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* black */

  color: #192a3e;
}

.CadastrosAtuvosContainer-subtitle {
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* black */

  color: #192a3e;
}

.cadastrosAtivos-content {
  display: flex;
  flex-direction: column;
}

.cadastrosAtivos-content-row-title {
  display: flex;
  flex-direction: row;

  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.1em;

  color: #000000;

  padding: 15px 0px;

  border-bottom: 1px solid #dfe0eb;
}

.cadastrosAtivos-content-row-item-title-1 {
  width: 494px;
}

.cadastrosAtivos-content-row-item-title-2 {
  width: 267px;
}

.cadastrosAtivos-content-row-item-title-3 {
  width: 267px;
}

.cadastrosAtivos-row-content-item-1 {
  width: 494px;
}
.cadastrosAtivos-row-content-item-2 {
  width: 267px;
}

.cadastrosAtivos-row-content-item-3 {
  width: 267px;
}

.cadastrosAtivos-row-content-item-4 {
  width: 40px;
  cursor: pointer;
}

.button-focus {
  font-family: Encode Sans;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.06em;
  line-height: 20px;
  color: #000000;
  width: 100%;
  transition: all 0.3s linear;
  cursor: pointer;
  border: 1px solid #ececec;
  border-radius: 0px 0px 10px 10px;
  transition: all 0.3s linear;
}

.button-focus:hover {
  background-color: #cfe7ff;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

body {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.client-name-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  height: 80px;
}

.client-name-area p {
  margin: 0;
}

.info-contrato-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.info-contrato {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 1rem;
}

.info-contrato-item {
  text-align: center;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info-contrato-item2 {
  width: 260px;
}

.info-contrato-item3 {
  width: 190px;
}

@media (max-width: 970px) {
  .info-contrato-item3 {
    width: 260px;
  }
}

.info-contrato span {
  color: #7e7e82;
  margin-bottom: 8px;
  text-align: center;
}

.info-contrato p {
  color: #212322;
  margin: 0;
  text-align: center;
}

.container-dadosReceita-master{
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: black;
}

.container-dadosReceita{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    background-color: white;
    width: 1200px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.container-dadosReceita-title{
    display: flex;
    justify-content: flex-start;   
}

.container-icons-dadosReceita{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    align-items: baseline;
    
}

.dadosReceita-title{
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -1px;
    margin-left: 30px;
}

.container-dadosReceita-content{
    margin-top: 25px;
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
}
.dadosReceita-name{
    font-weight: bold;
    width: 100px;
    text-align: left;
    line-height: 0px;
    font-size: 14px;

}

.linha-separadora{
    color: transparent;
    background-color: #e5e5e5;
    height: 1px;
}

.dadosReceita-name-content{
    width: 200px;
    text-align: left;
    line-height: 0px;
    font-size: 14px;
}

.dadosReceita-name-content-2{
    line-height: 0px;
    font-size: 14px;

}

.documents-line{
    display: flex;
}

.item-container-dadosReceita{
    width: 30px;
    height: 30px;
    background-color: #9e9e9e;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: white;
    
    font-size: 28px;

}

.sep-line{
    background-color: black;
    height: 2px;
    width: 1200px;
}
.container-mercadoLivre {
  margin: 0;
  font-family: "Encode Sans", sans-serif;
  width: 100%;
  overflow: hidden;
}

.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy h1 {
  font-size: 24px;
  margin-bottom: 40px;
  margin-top: 20px;
  text-align: center;
  font-weight: 900;
}

.policy-section {
  margin-bottom: 30px;
}

.policy-section h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.policy-section p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@font-face {
  font-family: "Poppins-Light";
  src: url(/static/media/Poppins-Light.fcc40ae9.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(/static/media/Poppins-Bold.08c20a48.ttf) format("truetype");
}

.logoBrand {
  max-width: 100px;
  height: auto;
  transition: all 0.5s ease-out;
}

.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: rgb(212, 212, 212);
  height: 2px;
  transition-property: right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-from-left:hover:before,
.hvr-underline-from-left:focus:before,
.hvr-underline-from-left:active:before {
  right: 0;
}

.navbar-nav {
  width: 100%;
  justify-content: space-evenly;
  font-weight: 500;
  margin-left: 20px;
}

.navbar-collapse {
  justify-content: center;
}

.img-fortlev {
  width: 240px;
  height: auto;
  margin: 0 auto;
}

.img-fortlev-header {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 16px;
  background-color: #878787;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.caption-fortlev {
  text-align: center;
  margin: 0 auto;
  padding: 0 16px;
  border-radius: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #00386d;
}

.img-carousel-fortlev-1 {
  width: 100%;
  height: 85vh;
  object-fit: cover;
  object-position: 50% 80%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.img-carousel-fortlev-2 {
  width: 100%;
  height: 85vh;
  object-fit: cover;
  object-position: 50% 70%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.img-carousel-fortlev-3 {
  width: 100%;
  height: 85vh;
  object-fit: fill;
  /* object-position: 50% 90%; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.carousel-container-fortlev {
  width: 100%;
  height: 85vh;
}

.row-header-fortlev {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 75vh;
  height: 100%;
}

.first-col-header-fortlev {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.second-col-header-fortlev {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 70%;
  padding: 0 32px;
  margin: auto 0;
  grid-gap: 16px;
  gap: 16px;
  justify-content: space-between;
}

.text-header-fortlev {
  font-size: 40px;
  color: #878787;
  font-family: "Poppins-Bold", sans-serif;
}

.second-text-header-fortlev {
  font-size: 40px;
  color: #ee7528;
  font-family: "Poppins-Bold", sans-serif;
}

.button-header-fortlev {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 16px;
  margin: 0 auto;
  background-color: #ee7528;
  color: white;
  font-family: "Poppins-Light", sans-serif;
  font-size: 24px;
  border-radius: 16px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0); */
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.button-header-fortlev:hover {
  transform: scale(1.1);
  color: #00386d;
}

.veja-mais {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.veja-mais-button {
  padding: 10px 20px;
  border: 2px solid transparent;
  font-family: "Poppins-Light", sans-serif;
  color: #878787;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  -webkit-animation: loop 3s ease-in-out infinite;
          animation: loop 3s ease-in-out infinite;
}

.responsive-navbar-nav {
  background-color: white;
}

.veja-mais-button:hover {
  text-decoration: underline;
}

.container-schema {
  margin: 50px 0 0 0;
  background-color: #e5e5e5;
}

@-webkit-keyframes loop {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

@keyframes loop {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

#navbarId {
  position: fixed;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 0;
  width: 100%;
  z-index: 1020;
}

.logoBrand.hidden {
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.5s ease-out;
}

.modal-container {
  padding: 60px 0 40px 0;
  background-color: #ee7528;
  font-family: "Poppins-Light", sans-serif;
  color: #ffffff;
}

.footer-container-fortlev {
  padding: 20px 0;
  background-color: #ee7528;
  font-family: "Poppins-Light", sans-serif;
  color: #ffffff;
}

.icon-footer-fortlev {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.row-footer-fortlev {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.column-footer-fortlev {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.forms-login {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.forms-login h3 {
  color: #00386d;
  border: none;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}

.forms-login h3:hover {
  text-decoration: underline;
}

.footer-text-fortlev {
  width: 100%;
  font-weight: bold;
  color: white;
  padding: 8px 0;
}

@media only screen and (max-width: 1023px) {
  .row-footer-fortlev {
    flex-direction: column;
  }

  .row-header-fortlev {
    flex-direction: column-reverse;
    min-height: auto;
    justify-content: center;
    align-items: center;
  }

  .first-col-header-fortlev,
  .second-col-header-fortlev {
    width: 100%;
    margin: 0;
    margin-bottom: 16px;
  }

  .text-header-fortlev,
  .second-text-header-fortlev {
    font-size: 30px;
  }

  .column-footer-fortlev {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .img-carousel-fortlev-1,
  .img-carousel-fortlev-2 {
    object-fit: fill;
  }

  .img-carousel-fortlev-3 {
    object-fit: cover;
  }
}

/* -------------------------------------------------- */

.simulacao-button-fortlev-selected {
  background-color: #00386d;
  color: white;
  border: 1px solid #00386d;

  font-family: "Poppins-Light", sans-serif;
  font-weight: 300;
  font-size: 16px;

  text-align: center;
  cursor: pointer;

  margin: auto 0px;
  padding: 17px 38px;
}

.simulacao-button-fortlev {
  background-color: #ffffff;
  color: #00386d;
  border: 1px solid #00386d;

  font-family: "Poppins-Light", sans-serif;
  font-weight: 300;
  font-size: 16px;

  text-align: center;
  cursor: pointer;

  margin: auto 0px;
  padding: 17px 38px;
}

.simulacao-button-fortlev:hover {
  background-color: #f1f1f1;
}

.title-ml-header-fortlev {
  color: #00386d;
  font-weight: bold;
  font-size: 44px;
  letter-spacing: 0.02em;
  font-family: "Poppins-Bold", sans-serif;
}

.subtitle-ml-header-fortlev {
  margin-top: 30px;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #878787;
  font-family: "Poppins-Light", sans-serif;
}

button.register-button-fortlev {
  margin: 0px;
  left: 50%;
  font-weight: normal;
  text-align: center;
  width: 360px;
  background: #00386d;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-family: "Poppins-Light", sans-serif;
  transition: transform 0.3s ease;
}

button.register-button-fortlev:hover {
  color: #ee7528;
  transform: scale(1.1);
}

.ladoDireito h2,
.ladoDireito p,
.ladoDireito input,
.ladoDireito label {
  font-family: "Poppins", sans-serif;
}
.mainComponent .ladoDireito{
    
    justify-content: center;
}

.mainComponent .ladoDireito .direitos{
    margin-top: 80px;
}
.msg{
    max-width: 500px;
}

.ladoDireito .backHome img{
    width: 40px;
}

.handleSucesstrue{
    color: #40920c !important;
}

@media screen and (max-width:776px) {
    .msg{
        padding: 0px 15px;
     
    }
}
.infosAdm p,
.infosAdm h2,
.infosAdm button,
.infosAdm span {
  font-family: "Poppins", sans-serif;
}

.titleAdm {
  margin-top: 40px;
  font-weight: 400;
  font-size: 1.7em;
}

.infosAdm p {
  margin: 0;
}

.btnsAdm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.btnsAdm button {
  font-weight: 500;
  background-color: transparent;
  border: none;
  outline: 0;
  margin: 20px 0;
  padding: 0px 20px;
  width: 100%;
  border-radius: 5px;
  height: 100%;
}

.btnsAdm a {
  width: 49%;
}

.infosAdm .parterInfosContainer {
  margin-top: 60px;
}

.btnsAdm a:nth-child(1) button {
  background-color: #74f325;
  border: 1px solid #a0f75a;
}

.btnsAdm a:nth-child(2) button {
  border: 1px solid black;
}

@media screen and (max-width: 776px) {
  .btnsAdm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btnsAdm a {
    width: 100%;
  }
  .btnsAdm a button {
    font-size: 0.8em;
  }

  .infosAdm .parterInfosContainer {
    width: 100%;
  }

  .infosAdm .parterInfosContainer h3 {
    margin: 0 auto;
  }
  .btnsAdm {
    grid-gap: 10px;
    gap: 10px;
    margin-top: 40px;
  }

  .btnsAdm button {
    margin: 5px 0;
    padding: 10px 0;
  }
  .infosAdm .parterInfosContainer {
    margin: 20px 0;
  }
}

.infosAdm .infosEmpresas .allContent .indicados {
  overflow-x: visible;
  padding: 0px 10px;
}
.infosAdm .infosEmpresas .allContent {
  max-width: 1280px !important;
}

.infosAdm .infosEmpresas .allContent .indicados {
  max-width: 1280px;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  height: 580px;
  margin-top: 20px;
  padding-bottom: 10px;
  overflow-x: auto;
  scrollbar-width: auto !important;
}

.infosAdm .infosEmpresas .allContent .indicados .infosIndicado {
  width: 100%;
  max-width: 250px;
  min-width: 250px;
}

.infosAdm .infosEmpresas .buttonNext,
.infosEmpresas .buttonBack,
.buttonsMobile {
  display: none;
}
.pesquisaH2 {
  margin-top: 60px;
}
.procuraEmpresa {
  width: 100%;
  border: 1px solid #cdced7;
  padding: 5px 20px;
}
.infosAdm input {
  margin: 10px 0;
  border-radius: 6px;
}

.infosAdm .allContent .buttonsMobile{
  display: none !important;
  
}




@media screen and (max-width: 767px) {
  .infosAdm .infosEmpresas .allContent .indicados .infosIndicado {
    width: 100%;
    max-width: 100%;
  }
  .pesquisaH2 {
    font-size: 1.5em;
  }
}

