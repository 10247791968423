.infosAdm .infosEmpresas .allContent .indicados {
  overflow-x: visible;
  padding: 0px 10px;
}
.infosAdm .infosEmpresas .allContent {
  max-width: 1280px !important;
}

.infosAdm .infosEmpresas .allContent .indicados {
  max-width: 1280px;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  height: 580px;
  margin-top: 20px;
  padding-bottom: 10px;
  overflow-x: auto;
  scrollbar-width: auto !important;
}

.infosAdm .infosEmpresas .allContent .indicados .infosIndicado {
  width: 100%;
  max-width: 250px;
  min-width: 250px;
}

.infosAdm .infosEmpresas .buttonNext,
.infosEmpresas .buttonBack,
.buttonsMobile {
  display: none;
}
.pesquisaH2 {
  margin-top: 60px;
}
.procuraEmpresa {
  width: 100%;
  border: 1px solid #cdced7;
  padding: 5px 20px;
}
.infosAdm input {
  margin: 10px 0;
  border-radius: 6px;
}

.infosAdm .allContent .buttonsMobile{
  display: none !important;
  
}




@media screen and (max-width: 767px) {
  .infosAdm .infosEmpresas .allContent .indicados .infosIndicado {
    width: 100%;
    max-width: 100%;
  }
  .pesquisaH2 {
    font-size: 1.5em;
  }
}
